module RtBotError = struct

type t = {
  code: string;
  errorId: string;
  message: string;
  startLine: int;
  startColumn: int;
  endLine: int;
  endColumn: int;
}

end