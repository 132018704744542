module RtBotContext = struct
  open Operator
  open Connection
  open Config
  open Dictionary
  open Position
  open Serror


  type symbolType = [ `Operator | `Stream of int | `None ]
  type symbol = { reference : string; sType : symbolType }
  type pendingConnection = { name: string; left:string located option; reference : string option; index : int option; input : string option; pos: position; output: string located option} 

  type t = {
    errors: RtBotError.t list;
    entryOperator: string;
    outputOperator: string;
    operatorsConfig: RtBotConfig.operatorsConfig;
    outliersConfig: RtBotConfig.outliersConfig;
    econfig: RtBotConfig.semanticConfig;
    symbols : (string * symbol) list;
    operators : (string * RtBotOperator.t) list;
    connections : RtBotConnection.t list;
    pendingConnections : (string * pendingConnection) list;
  }

  type rightValue = { name : string; category : symbolType }
  type contextPair = { rightValue : rightValue; context : t }

  let context_contains_errors (p : t): bool = 
    not(List.is_empty p.errors)

  let rec errors_contains_error(code: string) (el: RtBotError.t list): bool =
    match el with
    | [] -> false
    | h :: t -> if (h.code = code) then true else errors_contains_error code t

  let context_contains_error (code:string) (p: t): bool =
    errors_contains_error code p.errors

    
  let json_of_context (p : t) : Yojson.Basic.t =
    if ( not (List.is_empty p.errors))
      then
        `Assoc
          [ ( "errors",
              `List
                (let to_json (con : RtBotError.t) =
                  `Assoc
                    [
                      ("message", `String con.message);
                      ("code", `String con.code);
                      ("startLine", `String (string_of_int con.startLine));
                      ("startColumn", `String (string_of_int con.startColumn));
                      ("endLine", `String (string_of_int con.endLine));
                      ("endColumn", `String (string_of_int con.endColumn));
                    ]
                in
                List.map to_json p.errors));            
          ]        
      else  
        `Assoc
          [ ( "errors",
              `List
                ([]));
            ( "program",
              `Assoc          
            [ ("entryOperator", `String p.entryOperator);
              ("output", 
              `Assoc 
              [p.outputOperator, `List(
                let rec get_ouput_ports(ports: string list):  Yojson.Basic.t list =
                  match ports with
                | [] -> []
                | hd :: tl -> (`String hd) :: get_ouput_ports tl
              in          
              let op = Option.get (RtBotDictionary.get p.outputOperator p.operators) in
              get_ouput_ports op.output
              )]);
              ( "operators",
              `List
                (let to_json (op : RtBotOperator.t) =
                  let operators =
                    [ ("id", `String op.id); ("type", `String op.fName) ]
                    @
                    let rec json_of_parameter p =
                      match p with
                      | `RtBotInt i -> `Int i
                      | `RtBotFloat f -> `Float f
                      | `RtBotCollection l -> `List (List.map json_of_parameter l)
                    and f (p : RtBotOperator.parameter) = (p.pName, json_of_parameter p.pType) in
                    List.map f op.parameters
                  in
                  `Assoc operators
                in
                List.map to_json (RtBotDictionary.get_values p.operators)) );
            ( "connections",
              `List
                (let to_json (con : RtBotConnection.t) =
                  `Assoc
                    [
                      ("from", `String con.fromOp);
                      ("to", `String con.toOp);
                      ("fromPort", `String con.fromPort);
                      ("toPort", `String con.toPort);
                    ]
                in

                List.map to_json p.connections) );
          ]);
        ]
end
