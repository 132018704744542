open Rtbotc.Main
open Rtbotc.Framework
open Rtbotc.Errors
open Js_of_ocaml

let () =
  Js.export "rtbotc"
    (object%js
       method compile program = compile program fjson ejson
    end)
