module RtBotConfig = struct
  open Dictionary

  type paramsType = [ `PInt | `PString | `PFloat ]
  type param = { name : string; minValue : float option; collection: bool; dataType : paramsType list; minItems: int option }
  type operatorConfig = { name : string; input : string list; output : string list; control : string list; params : param list }
  type outlierConfig = { name : string; }
  type operatorsConfig = { operatorsMap : (string * operatorConfig) list }
  type outliersConfig = { outliersMap : (string * outlierConfig) list }

  type eConfig = { category: string; message:string; errorId: string }
  type semanticConfig = { errorsMap: (string * eConfig) list}

  let get_key (a, _) = a
  let get_value (_, b) = b

  let string_of_of_param_type (p: paramsType): string =
    match p with   
    | `PInt -> "int"
    | `PFloat -> "float"
    | `PString -> "string"

  let rec string_list_of_param_type_list (pl: paramsType list): string list =
    match pl with
    | [] -> []
    | h :: t -> string_of_of_param_type h :: string_list_of_param_type_list t


  let string_of_param_type_list (pl: paramsType list): string =
    let l = string_list_of_param_type_list pl in String.concat " or " l


  let load_params_type (o : Yojson.Basic.t) : paramsType =
    let t = Yojson.Basic.Util.to_string o in
    match t with
    | "int" -> `PInt
    | "float" -> `PFloat
    | "string" -> `PString
    | t -> failwith (__FUNCTION__ ^ "Unexpected type " ^ t ^ " found in config")


  let rec load_param_type_list (ls : Yojson.Basic.t list): paramsType list =
    match ls with
    | [] -> []
    | h :: t  -> (load_params_type h) :: load_param_type_list t


  let load_params_types(l : Yojson.Basic.t): paramsType list =
  match l with `Null -> [] | ls -> load_param_type_list (Yojson.Basic.Util.to_list ls)

  let load_collection (o : Yojson.Basic.t): bool =
    if o = `Null then false
    else Yojson.Basic.Util.to_bool o


  let load_param (o : Yojson.Basic.t) : param =
    {
      name = o |> Yojson.Basic.Util.member "name" |> Yojson.Basic.Util.to_string;
      minValue = o |> Yojson.Basic.Util.member "minValue" |> Yojson.Basic.Util.to_number_option;
      collection =  o |> Yojson.Basic.Util.member "collection" |> load_collection;
      dataType = o |> Yojson.Basic.Util.member "dataType" |> load_params_types;
      minItems = o |> Yojson.Basic.Util.member "minItems" |> Yojson.Basic.Util.to_int_option;
    }

  let rec load_param_list (l : Yojson.Basic.t list) : param list =
    match l with [] -> [] | h :: t -> load_param h :: load_param_list t

  let load_params (l : Yojson.Basic.t) : param list =
    match l with `Null -> [] | ls -> load_param_list (Yojson.Basic.Util.to_list ls)  

  let rec load_string_list_from_json_list (l : Yojson.Basic.t list) : string list =
    match l with [] -> [] | h :: t -> Yojson.Basic.Util.to_string h :: load_string_list_from_json_list t 

  let load_string_list (l : Yojson.Basic.t) : string list =
    match l with `Null -> [] | ls -> load_string_list_from_json_list (Yojson.Basic.Util.to_list ls)
  
  let load_operator_config (o : Yojson.Basic.t) : operatorConfig =
    {
      name = o |> Yojson.Basic.Util.member "name" |> Yojson.Basic.Util.to_string;
      input = o |> Yojson.Basic.Util.member "input" |> load_string_list;
      output = o |> Yojson.Basic.Util.member "output" |> load_string_list;
      control = o |> Yojson.Basic.Util.member "control" |> load_string_list;
      params = o |> Yojson.Basic.Util.member "params" |> load_params;
    }

  let load_outlier_config (o : Yojson.Basic.t) : outlierConfig =
    {
      name = o |> Yojson.Basic.Util.member "name" |> Yojson.Basic.Util.to_string;
    }

  let rec load_operator_list (l : (string * Yojson.Basic.t) list) : (string * operatorConfig) list =
    match l with [] -> [] | h :: t -> (get_key h, load_operator_config (get_value h)) :: load_operator_list t

  let rec load_outlier_list (l : (string * Yojson.Basic.t) list) : (string * outlierConfig) list =
    match l with [] -> [] | h :: t -> (get_key h, load_outlier_config (get_value h)) :: load_outlier_list t

  let load_operators_dictionary (json : Yojson.Basic.t) : (string * operatorConfig) list =
    load_operator_list (Yojson.Basic.Util.to_assoc json)

  let load_outliers_dictionary (json : Yojson.Basic.t) : (string * outlierConfig) list =
    load_outlier_list (Yojson.Basic.Util.to_assoc json)

  let load_operators (json : Yojson.Basic.t) : operatorsConfig = { operatorsMap = json |> Yojson.Basic.Util.member "operators" |> load_operators_dictionary }

  let load_outliers (json : Yojson.Basic.t) : outliersConfig = { outliersMap = json |> Yojson.Basic.Util.member "outliers" |> load_outliers_dictionary }

  let load_error_config (o : Yojson.Basic.t) (errorId: string) : eConfig =
    {
      category = o |> Yojson.Basic.Util.member "category" |> Yojson.Basic.Util.to_string;
      message = o |> Yojson.Basic.Util.member "message" |> Yojson.Basic.Util.to_string;
      errorId = errorId
    }

  let rec load_semantic_list (l : (string * Yojson.Basic.t) list) : (string * eConfig) list =
    match l with [] -> [] | h :: t -> (get_key h, load_error_config (get_value h) (get_key h)) :: load_semantic_list t

  let load_errors (json : Yojson.Basic.t) : (string * eConfig) list =
    load_semantic_list (Yojson.Basic.Util.to_assoc json)

  let load_errors_config (json : Yojson.Basic.t) : semanticConfig = { errorsMap = load_errors json }

  let get_operator_definition (name : string) (config : (string * operatorConfig) list) : operatorConfig option =
    RtBotDictionary.get name config

  let get_outlier_definition (name : string) (config : (string * outlierConfig) list) : outlierConfig option =
    RtBotDictionary.get name config

  let get_error_definition   (name : string) (config : (string * eConfig) list) : eConfig option =
    RtBotDictionary.get name config

end
