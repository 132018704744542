open Config
open Serror
open Context
open Position
open Utils
open Dictionary


let get_error (errorId: string) (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  let eco = RtBotConfig.get_error_definition errorId c.econfig.errorsMap in
  if (Option.is_some eco)
    then
      let ec: RtBotConfig.eConfig = Option.get eco in
      let lines = get_lines p in
      let errors: RtBotError.t list =
          { code = ec.category; 
            message = RtBotUtils.i ec.message values;
            startLine = lines.startLine; 
            endLine = lines.endLine; 
            startColumn = lines.startColumn; 
            endColumn = lines.endColumn; 
            errorId = errorId;} :: c.errors in    
          {
            c with
            errors = errors;
          }
    else
      failwith (__FUNCTION__ ^ " " ^ errorId ^ " not found")


let get_error_001 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-001" p values c

let get_error_002 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-002" p values c

let get_error_003 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-003" p values c

let get_error_004 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-004" p values c

let get_error_005 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-005" p values c

let get_error_006 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-006" p values c

let get_error_007 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-007" p values c

let get_error_008 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-008" p values c

let get_error_009 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-009" p values c

let get_error_010 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-010" p values c

let get_error_011 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-011" p values c

let get_error_012 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-012" p values c

let get_error_013 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-013" p values c

let get_error_014 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-014" p values c

let get_error_015 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-015" p values c

let get_error_016 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-016" p values c

let get_error_017 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-017" p values c

let get_error_018 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-018" p values c

let get_error_019 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-019" p values c

let get_error_020 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-020" p values c

let get_error_021 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-021" p values c

let get_error_022 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-022" p values c

let get_error_023 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-023" p values c

let get_error_024 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-024" p values c

let get_error_025 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-025" p values c

let get_error_026 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-026" p values c

let get_error_027 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-027" p values c

let get_error_028 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-028" p values c

let get_error_029 (p:position) (values: string list) (c : RtBotContext.t) : RtBotContext.t =
  get_error "ERROR-029" p values c

let get_syntax_error  (p:position) (values: string list) (message: string) (c : RtBotConfig.semanticConfig) : RtBotError.t =
  let errorId = "SYNTAX-ERROR" in 
  let ec: RtBotConfig.eConfig = Option.get (RtBotConfig.get_error_definition errorId c.errorsMap) in
  let lines = get_lines p in
  let error: RtBotError.t =
      { code = ec.category; 
        message = (RtBotUtils.i ec.message values) ^ (if (String.length message = 0) then String.empty else " " ^ message);
        startLine = lines.startLine; 
        endLine = lines.endLine; 
        startColumn = lines.startColumn; 
        endColumn = lines.endColumn; 
        errorId = errorId;} in error


let  generate_symbol_declaration_pending_error (pc: RtBotContext.pendingConnection) (c: RtBotContext.t): RtBotError.t =
  let errorId = "ERROR-012" in
  let lines =  get_lines pc.pos in
  let ec: RtBotConfig.eConfig = Option.get (RtBotConfig.get_error_definition errorId c.econfig.errorsMap) in
  {   code = ec.category; 
      message = RtBotUtils.i ec.message [pc.name];
      startLine = lines.startLine; 
      endLine = lines.endLine; 
      startColumn = lines.startColumn; 
      endColumn = lines.endColumn; 
      errorId = errorId;
  }

let generate_symbol_declaration_pending_errors (pcs: (string * RtBotContext.pendingConnection) list) (c: RtBotContext.t): RtBotError.t list = 
  match pcs with
  | [] -> []
  | h :: _ -> (generate_symbol_declaration_pending_error (RtBotDictionary.get_value h) c) :: []

let generate_symbol_declaration_pending_errors_from_context (c: RtBotContext.t): RtBotError.t list =
  generate_symbol_declaration_pending_errors c.pendingConnections c






  