%{
open Ast
%}

%token <string> FLOAT
%token <string> INT
%token <string> ID
%token IN
%token OUT
%token <string> DEMULT
%token <string> LINEAR
%token <string> JOIN
%token <string> NAME
%token LPAREN
%token RPAREN
%token LBRACKET
%token RBRACKET
%token EQUALS
%token COMMA
%token PLUS
%token MINUS
%token TIMES
%token DIV
%token EOF

%left PLUS MINUS
%left TIMES DIV
%nonassoc UMINUS


%start <Ast.ast> prog

%%
%inline located(X):
  x=X { Position.with_poss $startpos $endpos x }

prog:
	| i = input; assigns = list(assignment); o = output EOF { `Program {input = i; assignments = assigns; output = o} }	
	;

input:
	| id = located(ID); EQUALS; IN LPAREN; params = located(expr) RPAREN;  { {inputName = id; inputParams = params} }
	;

output:
	| OUT LPAREN; input = located(expr) RPAREN;  { input }
	;

assignment: 
	| id = located(ID); EQUALS; e = located(expr) { {variableName = id; assignmentValue = e} }
	;
	
expr:
    | c = located(collection) { `RtBotCollection c }
    | f = located(FLOAT) { `RtBotFloat f }
	| i = located(INT) { `RtBotInt i }
	| left = located(expr) PLUS; right = located(expr) { `RtBotPlus {left = left; right = right} }
	| left = located(expr) MINUS; right = located(expr) { `RtBotMinus {left = left; right = right} }
	| left = located(expr) TIMES; right = located(expr) { `RtBotTimes {left = left; right = right} }
	| left = located(expr) DIV; right = located(expr) { `RtBotDiv {left = left; right = right} }
	| LPAREN; inner = located(expr) RPAREN; { `RtBotParent inner }
	| MINUS inner = located(expr) %prec UMINUS { `RtBotUMinus inner }
	| id = located(ID); output = option(index) { `RtBotVariable {variableName = id; variableOutput = output} }
	| name = located(JOIN) LPAREN; input = located(expr) COMMA; control = located(expr) COMMA; params = located(expr) RPAREN;	{ `RtBotJoin {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} }
	| name = located(LINEAR) LPAREN; input = located(expr) COMMA; control = located(expr) COMMA; params = located(expr) RPAREN; { `RtBotLinear {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} }
	| name = located(DEMULT) LPAREN; input = located(expr) COMMA; control = located(expr) COMMA; params = located(expr) RPAREN; { `RtBotDemultiplexer {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} }
	| name = located(NAME); LPAREN; input = located(expr) COMMA; control = located(expr) COMMA; params = located(expr) RPAREN;  { `RtBotOperator {operatorName = name; operatorSignature = name.value; operatorInput = input; operatorControl = control; operatorParams = params;} }
	;

collection:
	| LBRACKET; p = separated_list(COMMA, expr)  RBRACKET; { p }
	;

index: 
	| LBRACKET; i = located(INT) RBRACKET { i }
	;