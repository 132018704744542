module RtBotUtils = struct

let random_id () = Random.int 1000 |> string_of_int

let rec merge_string_list (l1: string list) (l2: string list) : string =
  match l1 , l2 with
  | [], _ -> ""
  | h1::[], [] -> h1 ^ merge_string_list [] []
  | h1::t1, [] -> h1 ^ "$" ^ merge_string_list t1 []
  | h1 :: t1, h2:: t2 -> h1 ^ h2 ^ merge_string_list t1 t2

let i (s:string) (params: string list) =
  let parts = String.split_on_char '$' s in
  merge_string_list parts params


let rec generate_ports (prefix:string) (num:int): string list =
  match num with
  | 0 -> []
  | x -> List.rev ((prefix ^ string_of_int x) :: (generate_ports prefix (x - 1)))

end