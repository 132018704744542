module Arithmetics = struct
let max_int = Stdlib.max_int
let min_int = Stdlib.min_int

exception Overflow

let modulo x y =
  let result = x mod y in
  if result >= 0 then result
  else result + y

let sign a = 
  compare a 0

let abs n =    
  let u = n asr Sys.int_size in
  n lxor u - u

let min a b =
  let d = b - a in (* overflow is correctly dealt with *)
  let s = a lxor b in
  let r = (s land b) lor (lnot s land d) in
  a + (d land (r asr Sys.int_size))

let max a b =
  let d = b - a in (* overflow is correctly dealt with *)
  let s = a lxor b in
  let r = (s land b) lor (lnot s land d) in
  b - (d land (r asr Sys.int_size))

let pred a =  
  if a = min_int then
    raise Overflow
  else
    a - 1

let succ a =  
  if a = max_int then
    raise Overflow
  else
    a + 1

let add a b =  
  let s = a + b in
  if (a lxor b) lor (a lxor lnot s) < 0 then
    s
  else
    raise Overflow

let sub a b = 
  let d = a - b in
  if (a lxor b) land (a lxor d) >= 0 then
    d
  else
    raise Overflow
      
  let rec mulx a b =
    if (a == 0 || b == 0) then 0
    else if (abs a > abs b) then mulx b a
    else
      if (modulo a 10 == 0)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b (add b (add b (add b (add b (add b (add b b))))))))) (mulx (sub a (10 * signa)) b)  in m
      else if (modulo a 10 == 9)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b (add b (add b (add b (add b (add b b)))))))) (mulx (sub a (9 * signa)) b)  in m
      else if (modulo a 10 == 8)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b (add b (add b (add b (add b b))))))) (mulx (sub a (8 * signa)) b)  in m
      else if (modulo a 10 == 7)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b (add b (add b (add b b)))))) (mulx (sub a (7 * signa)) b)  in m
      else if (modulo a 10 == 6)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b (add b (add b b))))) (mulx (sub a (6 * signa)) b)  in m
      else if (modulo a 10 == 5)
       then
        let signa = sign a in   
        let m = add  (add b (add b (add b (add b b)))) (mulx (sub a (5 * signa)) b)  in m
      else if (modulo a 10 == 4)
       then
        let signa = sign a in   
        let m = add (add b (add b (add b b))) (mulx (sub a (4 * signa)) b)  in m
      else if (modulo a 10 == 3)
       then
        let signa = sign a in   
        let m = add (add b (add  b b)) (mulx (sub a (3 * signa)) b)  in m   
      else if (modulo a 10 == 2)
       then
        let signa = sign a in   
        let m = add (add b b) (mulx (sub a (2 * signa)) b)  in m
      else
        let signa = sign a in
        let m = add b (mulx (sub a signa) b) in m
      
    
    let mul a b = 
    if (a == min_int && (b != 1)  ) then raise Overflow
    else if (a == min_int && (b == 1)  )  then a
    else if  (b == min_int && (a != 1)  ) then raise Overflow
    else if (b == min_int && (a == 1)  )  then b
    else 
     let m = if (a < b) then try mulx b a with Stack_overflow -> raise Overflow else try mulx a b with Stack_overflow -> raise Overflow in
     if (m == min_int && ((sign a) < 0) && ((sign b) < 0) ) then raise Overflow
     else if (((sign a) < 0) && ((sign b) < 0) ) then  -1 * m else m


end