open Ast
open Lexer
open Lexing
open Generate
open Config
open Context
open Semantic
open Position

(** [parse s] parses [s] into an AST. *)
let parse (s : string) (c: RtBotConfig.semanticConfig) : ast =
  let lexbuf = Lexing.from_string s in
  let tree =
    try Parser.prog Lexer.read lexbuf with
    | SyntaxError msg ->      
      `SyntaxFailure (get_syntax_error (position_of_lexing_position lexbuf.lex_start_p lexbuf.lex_curr_p) [] msg c);
    | Parser.Error ->
      let msg = ("Unexpected token" ^ " '" ^ (Lexing.lexeme lexbuf)^ "' " ^ "found.") in
      `SyntaxFailure (get_syntax_error (position_of_lexing_position lexbuf.lex_start_p lexbuf.lex_curr_p) [] msg c);
  in
  tree

let generate (program : program) (operatorsConfig : RtBotConfig.operatorsConfig) (outliersConfig: RtBotConfig.outliersConfig) (econfig: RtBotConfig.semanticConfig) : RtBotContext.t =
  let c : RtBotContext.t =
    { 
      entryOperator = String.empty;
      outputOperator = String.empty;
      econfig = econfig;
      operatorsConfig = operatorsConfig;
      outliersConfig = outliersConfig;
      symbols = [];
      operators = [];
      connections = [];
      pendingConnections = [];
      errors = [];
    }
  in
  let c1 = generate_program program c in
  c1

let get_ast (code:string) (econfig:RtBotConfig.semanticConfig): ast =  
  let tree = parse code econfig in tree

let compile_get_econtext (code:string) (f:string) (e:string): RtBotContext.t =
  let fjson = Yojson.Basic.from_string f in
  let operatorsConfig = RtBotConfig.load_operators fjson in
  let outliersConfig = RtBotConfig.load_outliers fjson in
  let ejson = Yojson.Basic.from_string e in
  let econfig = RtBotConfig.load_errors_config ejson in
  let tree = get_ast code econfig in
  match tree with
  | `Program p -> let econtext = generate p operatorsConfig outliersConfig econfig in econtext
  | `SyntaxFailure e ->
    let econtext: RtBotContext.t =
    { econfig = econfig; 
      operatorsConfig = operatorsConfig; 
      outliersConfig = outliersConfig; 
      symbols = []; 
      operators = []; 
      connections = []; 
      pendingConnections = [];
      entryOperator = String.empty;
      outputOperator = String.empty;
      errors = e::[]; } in econtext 

let compile (code:string) (f:string) (e:string): string =
  let econtext = compile_get_econtext code f e in
  let context_json = RtBotContext.json_of_context econtext in
                  Yojson.Basic.pretty_to_string context_json
