
module MenhirBasics = struct
  
  exception Error
  
  let _eRR =
    fun _s ->
      raise Error
  
  type token = 
    | TIMES
    | RPAREN
    | RBRACKET
    | PLUS
    | OUT
    | NAME of (
# 13 "lib/parser.mly"
       (string)
# 20 "lib/parser.ml"
  )
    | MINUS
    | LPAREN
    | LINEAR of (
# 11 "lib/parser.mly"
       (string)
# 27 "lib/parser.ml"
  )
    | LBRACKET
    | JOIN of (
# 12 "lib/parser.mly"
       (string)
# 33 "lib/parser.ml"
  )
    | INT of (
# 6 "lib/parser.mly"
       (string)
# 38 "lib/parser.ml"
  )
    | IN
    | ID of (
# 7 "lib/parser.mly"
       (string)
# 44 "lib/parser.ml"
  )
    | FLOAT of (
# 5 "lib/parser.mly"
       (string)
# 49 "lib/parser.ml"
  )
    | EQUALS
    | EOF
    | DIV
    | DEMULT of (
# 10 "lib/parser.mly"
       (string)
# 57 "lib/parser.ml"
  )
    | COMMA
  
end

include MenhirBasics

# 1 "lib/parser.mly"
  
open Ast

# 69 "lib/parser.ml"

type ('s, 'r) _menhir_state = 
  | MenhirState04 : ('s _menhir_cell0_ID _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 04.
        Stack shape : ID LPAREN.
        Start symbol: prog. *)

  | MenhirState06 : (('s, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 06.
        Stack shape : NAME LPAREN.
        Start symbol: prog. *)

  | MenhirState07 : (('s, _menhir_box_prog) _menhir_cell1_MINUS, _menhir_box_prog) _menhir_state
    (** State 07.
        Stack shape : MINUS.
        Start symbol: prog. *)

  | MenhirState08 : (('s, _menhir_box_prog) _menhir_cell1_LPAREN, _menhir_box_prog) _menhir_state
    (** State 08.
        Stack shape : LPAREN.
        Start symbol: prog. *)

  | MenhirState10 : (('s, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 10.
        Stack shape : LINEAR LPAREN.
        Start symbol: prog. *)

  | MenhirState11 : (('s, _menhir_box_prog) _menhir_cell1_LBRACKET, _menhir_box_prog) _menhir_state
    (** State 11.
        Stack shape : LBRACKET.
        Start symbol: prog. *)

  | MenhirState13 : (('s, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 13.
        Stack shape : JOIN LPAREN.
        Start symbol: prog. *)

  | MenhirState23 : (('s, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 23.
        Stack shape : DEMULT LPAREN.
        Start symbol: prog. *)

  | MenhirState25 : (('s, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 25.
        Stack shape : expr.
        Start symbol: prog. *)

  | MenhirState28 : (('s, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 28.
        Stack shape : expr.
        Start symbol: prog. *)

  | MenhirState30 : (('s, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 30.
        Stack shape : expr.
        Start symbol: prog. *)

  | MenhirState32 : (('s, _menhir_box_prog) _menhir_cell1_expr _menhir_cell0_MINUS, _menhir_box_prog) _menhir_state
    (** State 32.
        Stack shape : expr MINUS.
        Start symbol: prog. *)

  | MenhirState34 : ((('s, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 34.
        Stack shape : DEMULT LPAREN expr.
        Start symbol: prog. *)

  | MenhirState36 : (((('s, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 36.
        Stack shape : DEMULT LPAREN expr expr.
        Start symbol: prog. *)

  | MenhirState40 : ((('s, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 40.
        Stack shape : JOIN LPAREN expr.
        Start symbol: prog. *)

  | MenhirState42 : (((('s, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 42.
        Stack shape : JOIN LPAREN expr expr.
        Start symbol: prog. *)

  | MenhirState49 : (('s, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 49.
        Stack shape : expr.
        Start symbol: prog. *)

  | MenhirState52 : ((('s, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 52.
        Stack shape : LINEAR LPAREN expr.
        Start symbol: prog. *)

  | MenhirState54 : (((('s, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 54.
        Stack shape : LINEAR LPAREN expr expr.
        Start symbol: prog. *)

  | MenhirState61 : ((('s, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 61.
        Stack shape : NAME LPAREN expr.
        Start symbol: prog. *)

  | MenhirState63 : (((('s, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_state
    (** State 63.
        Stack shape : NAME LPAREN expr expr.
        Start symbol: prog. *)

  | MenhirState69 : ('s _menhir_cell0_input, _menhir_box_prog) _menhir_state
    (** State 69.
        Stack shape : input.
        Start symbol: prog. *)

  | MenhirState71 : (('s, _menhir_box_prog) _menhir_cell1_ID, _menhir_box_prog) _menhir_state
    (** State 71.
        Stack shape : ID.
        Start symbol: prog. *)

  | MenhirState75 : (('s _menhir_cell0_input, _menhir_box_prog) _menhir_cell1_list_assignment_ _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_state
    (** State 75.
        Stack shape : input list(assignment) LPAREN.
        Start symbol: prog. *)

  | MenhirState80 : (('s, _menhir_box_prog) _menhir_cell1_assignment, _menhir_box_prog) _menhir_state
    (** State 80.
        Stack shape : assignment.
        Start symbol: prog. *)


and ('s, 'r) _menhir_cell1_assignment = 
  | MenhirCell1_assignment of 's * ('s, 'r) _menhir_state * (Ast.assignment)

and ('s, 'r) _menhir_cell1_expr = 
  | MenhirCell1_expr of 's * ('s, 'r) _menhir_state * (Ast.expr) * Lexing.position * Lexing.position

and 's _menhir_cell0_input = 
  | MenhirCell0_input of 's * (Ast.programInput)

and ('s, 'r) _menhir_cell1_list_assignment_ = 
  | MenhirCell1_list_assignment_ of 's * ('s, 'r) _menhir_state * (Ast.assignment list)

and ('s, 'r) _menhir_cell1_DEMULT = 
  | MenhirCell1_DEMULT of 's * ('s, 'r) _menhir_state * (
# 10 "lib/parser.mly"
       (string)
# 214 "lib/parser.ml"
) * Lexing.position * Lexing.position

and ('s, 'r) _menhir_cell1_ID = 
  | MenhirCell1_ID of 's * ('s, 'r) _menhir_state * (
# 7 "lib/parser.mly"
       (string)
# 221 "lib/parser.ml"
) * Lexing.position * Lexing.position

and 's _menhir_cell0_ID = 
  | MenhirCell0_ID of 's * (
# 7 "lib/parser.mly"
       (string)
# 228 "lib/parser.ml"
) * Lexing.position * Lexing.position

and ('s, 'r) _menhir_cell1_JOIN = 
  | MenhirCell1_JOIN of 's * ('s, 'r) _menhir_state * (
# 12 "lib/parser.mly"
       (string)
# 235 "lib/parser.ml"
) * Lexing.position * Lexing.position

and ('s, 'r) _menhir_cell1_LBRACKET = 
  | MenhirCell1_LBRACKET of 's * ('s, 'r) _menhir_state * Lexing.position

and ('s, 'r) _menhir_cell1_LINEAR = 
  | MenhirCell1_LINEAR of 's * ('s, 'r) _menhir_state * (
# 11 "lib/parser.mly"
       (string)
# 245 "lib/parser.ml"
) * Lexing.position * Lexing.position

and ('s, 'r) _menhir_cell1_LPAREN = 
  | MenhirCell1_LPAREN of 's * ('s, 'r) _menhir_state * Lexing.position

and 's _menhir_cell0_LPAREN = 
  | MenhirCell0_LPAREN of 's * Lexing.position

and ('s, 'r) _menhir_cell1_MINUS = 
  | MenhirCell1_MINUS of 's * ('s, 'r) _menhir_state * Lexing.position

and 's _menhir_cell0_MINUS = 
  | MenhirCell0_MINUS of 's * Lexing.position

and ('s, 'r) _menhir_cell1_NAME = 
  | MenhirCell1_NAME of 's * ('s, 'r) _menhir_state * (
# 13 "lib/parser.mly"
       (string)
# 264 "lib/parser.ml"
) * Lexing.position * Lexing.position

and _menhir_box_prog = 
  | MenhirBox_prog of (Ast.ast) [@@unboxed]

let _menhir_action_01 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let e =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 279 "lib/parser.ml"
      
    in
    let id =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 288 "lib/parser.ml"
      
    in
    (
# 50 "lib/parser.mly"
                                               ( {variableName = id; assignmentValue = e} )
# 294 "lib/parser.ml"
     : (Ast.assignment))

let _menhir_action_02 =
  fun xs ->
    let p = 
# 241 "<standard.mly>"
    ( xs )
# 302 "lib/parser.ml"
     in
    (
# 71 "lib/parser.mly"
                                                        ( p )
# 307 "lib/parser.ml"
     : (Ast.expr list))

let _menhir_action_03 =
  fun _endpos_x_ _startpos_x_ x ->
    let c =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 318 "lib/parser.ml"
      
    in
    (
# 54 "lib/parser.mly"
                              ( `RtBotCollection c )
# 324 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_04 =
  fun _endpos_x_ _startpos_x_ x ->
    let f =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 335 "lib/parser.ml"
      
    in
    (
# 55 "lib/parser.mly"
                         ( `RtBotFloat f )
# 341 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_05 =
  fun _endpos_x_ _startpos_x_ x ->
    let i =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 352 "lib/parser.ml"
      
    in
    (
# 56 "lib/parser.mly"
                    ( `RtBotInt i )
# 358 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_06 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let right =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 370 "lib/parser.ml"
      
    in
    let left =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 379 "lib/parser.ml"
      
    in
    (
# 57 "lib/parser.mly"
                                                    ( `RtBotPlus {left = left; right = right} )
# 385 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_07 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let right =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 397 "lib/parser.ml"
      
    in
    let left =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 406 "lib/parser.ml"
      
    in
    (
# 58 "lib/parser.mly"
                                                     ( `RtBotMinus {left = left; right = right} )
# 412 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_08 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let right =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 424 "lib/parser.ml"
      
    in
    let left =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 433 "lib/parser.ml"
      
    in
    (
# 59 "lib/parser.mly"
                                                     ( `RtBotTimes {left = left; right = right} )
# 439 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_09 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let right =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 451 "lib/parser.ml"
      
    in
    let left =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 460 "lib/parser.ml"
      
    in
    (
# 60 "lib/parser.mly"
                                                   ( `RtBotDiv {left = left; right = right} )
# 466 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_10 =
  fun _endpos_x_ _startpos_x_ x ->
    let inner =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 477 "lib/parser.ml"
      
    in
    (
# 61 "lib/parser.mly"
                                         ( `RtBotParent inner )
# 483 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_11 =
  fun _endpos_x_ _startpos_x_ x ->
    let inner =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 494 "lib/parser.ml"
      
    in
    (
# 62 "lib/parser.mly"
                                            ( `RtBotUMinus inner )
# 500 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_12 =
  fun _endpos_x_ _startpos_x_ output x ->
    let id =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 511 "lib/parser.ml"
      
    in
    (
# 63 "lib/parser.mly"
                                            ( `RtBotVariable {variableName = id; variableOutput = output} )
# 517 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_13 =
  fun _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 ->
    let params =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 529 "lib/parser.ml"
      
    in
    let control =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined2_, _startpos_x_inlined2_, x_inlined2) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 539 "lib/parser.ml"
      
    in
    let input =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 549 "lib/parser.ml"
      
    in
    let name =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 558 "lib/parser.ml"
      
    in
    (
# 64 "lib/parser.mly"
                                                                                                                           ( `RtBotJoin {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} )
# 564 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_14 =
  fun _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 ->
    let params =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 576 "lib/parser.ml"
      
    in
    let control =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined2_, _startpos_x_inlined2_, x_inlined2) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 586 "lib/parser.ml"
      
    in
    let input =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 596 "lib/parser.ml"
      
    in
    let name =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 605 "lib/parser.ml"
      
    in
    (
# 65 "lib/parser.mly"
                                                                                                                             ( `RtBotLinear {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} )
# 611 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_15 =
  fun _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 ->
    let params =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 623 "lib/parser.ml"
      
    in
    let control =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined2_, _startpos_x_inlined2_, x_inlined2) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 633 "lib/parser.ml"
      
    in
    let input =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 643 "lib/parser.ml"
      
    in
    let name =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 652 "lib/parser.ml"
      
    in
    (
# 66 "lib/parser.mly"
                                                                                                                             ( `RtBotDemultiplexer {operatorName = name; operatorSignature = String.empty; operatorInput = input; operatorControl = control; operatorParams = params;} )
# 658 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_16 =
  fun _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 ->
    let params =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 670 "lib/parser.ml"
      
    in
    let control =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined2_, _startpos_x_inlined2_, x_inlined2) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 680 "lib/parser.ml"
      
    in
    let input =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 690 "lib/parser.ml"
      
    in
    let name =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 699 "lib/parser.ml"
      
    in
    (
# 67 "lib/parser.mly"
                                                                                                                             ( `RtBotOperator {operatorName = name; operatorSignature = name.value; operatorInput = input; operatorControl = control; operatorParams = params;} )
# 705 "lib/parser.ml"
     : (Ast.expr))

let _menhir_action_17 =
  fun _endpos_x_ _startpos_x_ x ->
    let i =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 716 "lib/parser.ml"
      
    in
    (
# 75 "lib/parser.mly"
                                       ( i )
# 722 "lib/parser.ml"
     : (string Position.located))

let _menhir_action_18 =
  fun _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 ->
    let params =
      let (_endpos_x_, _startpos_x_, x) = (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) in
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 734 "lib/parser.ml"
      
    in
    let id =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 743 "lib/parser.ml"
      
    in
    (
# 42 "lib/parser.mly"
                                                                        ( {inputName = id; inputParams = params} )
# 749 "lib/parser.ml"
     : (Ast.programInput))

let _menhir_action_19 =
  fun () ->
    (
# 216 "<standard.mly>"
    ( [] )
# 757 "lib/parser.ml"
     : (Ast.assignment list))

let _menhir_action_20 =
  fun x xs ->
    (
# 219 "<standard.mly>"
    ( x :: xs )
# 765 "lib/parser.ml"
     : (Ast.assignment list))

let _menhir_action_21 =
  fun () ->
    (
# 145 "<standard.mly>"
    ( [] )
# 773 "lib/parser.ml"
     : (Ast.expr list))

let _menhir_action_22 =
  fun x ->
    (
# 148 "<standard.mly>"
    ( x )
# 781 "lib/parser.ml"
     : (Ast.expr list))

let _menhir_action_23 =
  fun () ->
    (
# 111 "<standard.mly>"
    ( None )
# 789 "lib/parser.ml"
     : (string Position.located option))

let _menhir_action_24 =
  fun x ->
    (
# 114 "<standard.mly>"
    ( Some x )
# 797 "lib/parser.ml"
     : (string Position.located option))

let _menhir_action_25 =
  fun _endpos_x_ _startpos_x_ x ->
    let input =
      let _endpos = _endpos_x_ in
      let _startpos = _startpos_x_ in
      
# 35 "lib/parser.mly"
      ( Position.with_poss _startpos _endpos x )
# 808 "lib/parser.ml"
      
    in
    (
# 46 "lib/parser.mly"
                                              ( input )
# 814 "lib/parser.ml"
     : (Ast.programOutput))

let _menhir_action_26 =
  fun assigns i o ->
    (
# 38 "lib/parser.mly"
                                                         ( `Program {input = i; assignments = assigns; output = o} )
# 822 "lib/parser.ml"
     : (Ast.ast))

let _menhir_action_27 =
  fun x ->
    (
# 250 "<standard.mly>"
    ( [ x ] )
# 830 "lib/parser.ml"
     : (Ast.expr list))

let _menhir_action_28 =
  fun x xs ->
    (
# 253 "<standard.mly>"
    ( x :: xs )
# 838 "lib/parser.ml"
     : (Ast.expr list))

let _menhir_print_token : token -> string =
  fun _tok ->
    match _tok with
    | COMMA ->
        "COMMA"
    | DEMULT _ ->
        "DEMULT"
    | DIV ->
        "DIV"
    | EOF ->
        "EOF"
    | EQUALS ->
        "EQUALS"
    | FLOAT _ ->
        "FLOAT"
    | ID _ ->
        "ID"
    | IN ->
        "IN"
    | INT _ ->
        "INT"
    | JOIN _ ->
        "JOIN"
    | LBRACKET ->
        "LBRACKET"
    | LINEAR _ ->
        "LINEAR"
    | LPAREN ->
        "LPAREN"
    | MINUS ->
        "MINUS"
    | NAME _ ->
        "NAME"
    | OUT ->
        "OUT"
    | PLUS ->
        "PLUS"
    | RBRACKET ->
        "RBRACKET"
    | RPAREN ->
        "RPAREN"
    | TIMES ->
        "TIMES"

let _menhir_fail : unit -> 'a =
  fun () ->
    Printf.eprintf "Internal failure -- please contact the parser generator's developers.\n%!";
    assert false

include struct
  
  [@@@ocaml.warning "-4-37"]
  
  let rec _menhir_run_05 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_NAME (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LPAREN ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
          let _menhir_s = MenhirState06 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_07 : type  ttv_stack. ttv_stack -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _menhir_stack = MenhirCell1_MINUS (_menhir_stack, _menhir_s, _startpos) in
      let _menhir_s = MenhirState07 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_08 : type  ttv_stack. ttv_stack -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _menhir_stack = MenhirCell1_LPAREN (_menhir_stack, _menhir_s, _startpos) in
      let _menhir_s = MenhirState08 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_09 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_LINEAR (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LPAREN ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
          let _menhir_s = MenhirState10 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_11 : type  ttv_stack. ttv_stack -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _menhir_stack = MenhirCell1_LBRACKET (_menhir_stack, _menhir_s, _startpos) in
      let _menhir_s = MenhirState11 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | RBRACKET ->
          let _v = _menhir_action_21 () in
          _menhir_goto_loption_separated_nonempty_list_COMMA_expr__ _menhir_stack _menhir_lexbuf _menhir_lexer _v
      | _ ->
          _eRR ()
  
  and _menhir_run_12 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_JOIN (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LPAREN ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
          let _menhir_s = MenhirState13 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_14 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _tok = _menhir_lexer _menhir_lexbuf in
      let (_endpos_x_, _startpos_x_, x) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_05 _endpos_x_ _startpos_x_ x in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_goto_expr : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match _menhir_s with
      | MenhirState75 ->
          _menhir_run_76 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState71 ->
          _menhir_run_72 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState04 ->
          _menhir_run_66 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState63 ->
          _menhir_run_64 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState61 ->
          _menhir_run_62 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState06 ->
          _menhir_run_60 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState07 ->
          _menhir_run_59 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok
      | MenhirState08 ->
          _menhir_run_57 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState54 ->
          _menhir_run_55 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState52 ->
          _menhir_run_53 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState10 ->
          _menhir_run_51 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState49 ->
          _menhir_run_48 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState11 ->
          _menhir_run_48 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState42 ->
          _menhir_run_43 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState40 ->
          _menhir_run_41 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState13 ->
          _menhir_run_39 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState36 ->
          _menhir_run_37 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState34 ->
          _menhir_run_35 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState32 ->
          _menhir_run_33 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState30 ->
          _menhir_run_31 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok
      | MenhirState28 ->
          _menhir_run_29 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | MenhirState25 ->
          _menhir_run_26 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok
      | MenhirState23 ->
          _menhir_run_24 _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok
      | _ ->
          _menhir_fail ()
  
  and _menhir_run_76 : type  ttv_stack. ((ttv_stack _menhir_cell0_input, _menhir_box_prog) _menhir_cell1_list_assignment_ _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let (_endpos_x_, _startpos_x_, x) = (_endpos, _startpos, _v) in
          let _v = _menhir_action_25 _endpos_x_ _startpos_x_ x in
          (match (_tok : MenhirBasics.token) with
          | EOF ->
              let MenhirCell1_list_assignment_ (_menhir_stack, _, assigns) = _menhir_stack in
              let MenhirCell0_input (_menhir_stack, i) = _menhir_stack in
              let o = _v in
              let _v = _menhir_action_26 assigns i o in
              MenhirBox_prog _v
          | _ ->
              _eRR ())
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_25 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer ->
      let _menhir_s = MenhirState25 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_15 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_ID (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LBRACKET ->
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | INT _v_1 ->
              let _startpos_2 = _menhir_lexbuf.Lexing.lex_start_p in
              let _endpos_3 = _menhir_lexbuf.Lexing.lex_curr_p in
              let _tok = _menhir_lexer _menhir_lexbuf in
              (match (_tok : MenhirBasics.token) with
              | RBRACKET ->
                  let _endpos_4 = _menhir_lexbuf.Lexing.lex_curr_p in
                  let _tok = _menhir_lexer _menhir_lexbuf in
                  let (_endpos_x_, _startpos_x_, x, _endpos__3_) = (_endpos_3, _startpos_2, _v_1, _endpos_4) in
                  let _v = _menhir_action_17 _endpos_x_ _startpos_x_ x in
                  let _endpos = _endpos__3_ in
                  let (_endpos_x_, x) = (_endpos, _v) in
                  let _v = _menhir_action_24 x in
                  _menhir_goto_option_index_ _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_ _v _tok
              | _ ->
                  _eRR ())
          | _ ->
              _eRR ())
      | COMMA | DIV | ID _ | MINUS | OUT | PLUS | RBRACKET | RPAREN | TIMES ->
          let _v = _menhir_action_23 () in
          _menhir_goto_option_index_ _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _v _tok
      | _ ->
          _eRR ()
  
  and _menhir_goto_option_index_ : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_ID -> _ -> _ -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _v _tok ->
      let MenhirCell1_ID (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
      let (_endpos_output_, output) = (_endpos, _v) in
      let _v = _menhir_action_12 _endpos_x_ _startpos_x_ output x in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_output_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_run_21 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _tok = _menhir_lexer _menhir_lexbuf in
      let (_endpos_x_, _startpos_x_, x) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_04 _endpos_x_ _startpos_x_ x in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_run_22 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_DEMULT (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LPAREN ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
          let _menhir_s = MenhirState23 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_28 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer ->
      let _menhir_s = MenhirState28 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_32 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _menhir_stack = MenhirCell0_MINUS (_menhir_stack, _startpos) in
      let _menhir_s = MenhirState32 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_30 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer ->
      let _menhir_s = MenhirState30 in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | NAME _v ->
          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | MINUS ->
          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LPAREN ->
          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | LINEAR _v ->
          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | LBRACKET ->
          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
      | JOIN _v ->
          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | INT _v ->
          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | ID _v ->
          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | FLOAT _v ->
          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | DEMULT _v ->
          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_run_72 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_ID as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | ID _ | OUT ->
          let MenhirCell1_ID (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
          let _v = _menhir_action_01 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
          let _menhir_stack = MenhirCell1_assignment (_menhir_stack, _menhir_s, _v) in
          (match (_tok : MenhirBasics.token) with
          | ID _v_0 ->
              _menhir_run_70 _menhir_stack _menhir_lexbuf _menhir_lexer _v_0 MenhirState80
          | OUT ->
              let _v_1 = _menhir_action_19 () in
              _menhir_run_81 _menhir_stack _menhir_lexbuf _menhir_lexer _v_1
          | _ ->
              _menhir_fail ())
      | _ ->
          _eRR ()
  
  and _menhir_run_70 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _menhir_stack = MenhirCell1_ID (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | EQUALS ->
          let _menhir_s = MenhirState71 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_81 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_assignment -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v ->
      let MenhirCell1_assignment (_menhir_stack, _menhir_s, x) = _menhir_stack in
      let xs = _v in
      let _v = _menhir_action_20 x xs in
      _menhir_goto_list_assignment_ _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
  
  and _menhir_goto_list_assignment_ : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      match _menhir_s with
      | MenhirState80 ->
          _menhir_run_81 _menhir_stack _menhir_lexbuf _menhir_lexer _v
      | MenhirState69 ->
          _menhir_run_73 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _menhir_fail ()
  
  and _menhir_run_73 : type  ttv_stack. (ttv_stack _menhir_cell0_input as 'stack) -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      let _menhir_stack = MenhirCell1_list_assignment_ (_menhir_stack, _menhir_s, _v) in
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | LPAREN ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
          let _menhir_s = MenhirState75 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_66 : type  ttv_stack. (ttv_stack _menhir_cell0_ID _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let MenhirCell0_ID (_menhir_stack, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
          let _v = _menhir_action_18 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
          let _menhir_stack = MenhirCell0_input (_menhir_stack, _v) in
          (match (_tok : MenhirBasics.token) with
          | ID _v_0 ->
              _menhir_run_70 _menhir_stack _menhir_lexbuf _menhir_lexer _v_0 MenhirState69
          | OUT ->
              let _v_1 = _menhir_action_19 () in
              _menhir_run_73 _menhir_stack _menhir_lexbuf _menhir_lexer _v_1 MenhirState69
          | _ ->
              _eRR ())
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_64 : type  ttv_stack. ((((ttv_stack, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _endpos_0 = _menhir_lexbuf.Lexing.lex_curr_p in
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined2, _startpos_x_inlined2_, _endpos_x_inlined2_) = _menhir_stack in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined1, _startpos_x_inlined1_, _endpos_x_inlined1_) = _menhir_stack in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let MenhirCell1_NAME (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos__8_, _endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) = (_endpos_0, _endpos, _startpos, _v) in
          let _v = _menhir_action_16 _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos__8_ _startpos_x_ _v _menhir_s _tok
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_62 : type  ttv_stack. (((ttv_stack, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState63 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_60 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_NAME _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState61 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_59 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_MINUS -> _ -> _ -> _ -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok ->
      let MenhirCell1_MINUS (_menhir_stack, _menhir_s, _startpos__1_) = _menhir_stack in
      let (_endpos_x_, _startpos_x_, x) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_11 _endpos_x_ _startpos_x_ x in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_ _startpos__1_ _v _menhir_s _tok
  
  and _menhir_run_57 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _endpos_0 = _menhir_lexbuf.Lexing.lex_curr_p in
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell1_LPAREN (_menhir_stack, _menhir_s, _startpos__1_) = _menhir_stack in
          let (_endpos_x_, _startpos_x_, x, _endpos__3_) = (_endpos, _startpos, _v, _endpos_0) in
          let _v = _menhir_action_10 _endpos_x_ _startpos_x_ x in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos__3_ _startpos__1_ _v _menhir_s _tok
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_55 : type  ttv_stack. ((((ttv_stack, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _endpos_0 = _menhir_lexbuf.Lexing.lex_curr_p in
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined2, _startpos_x_inlined2_, _endpos_x_inlined2_) = _menhir_stack in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined1, _startpos_x_inlined1_, _endpos_x_inlined1_) = _menhir_stack in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let MenhirCell1_LINEAR (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos__8_, _endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) = (_endpos_0, _endpos, _startpos, _v) in
          let _v = _menhir_action_14 _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos__8_ _startpos_x_ _v _menhir_s _tok
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_53 : type  ttv_stack. (((ttv_stack, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState54 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_51 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_LINEAR _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState52 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_48 : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          let _menhir_s = MenhirState49 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | RBRACKET ->
          let x = _v in
          let _v = _menhir_action_27 x in
          _menhir_goto_separated_nonempty_list_COMMA_expr_ _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
      | _ ->
          _eRR ()
  
  and _menhir_goto_separated_nonempty_list_COMMA_expr_ : type  ttv_stack. ttv_stack -> _ -> _ -> _ -> (ttv_stack, _menhir_box_prog) _menhir_state -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s ->
      match _menhir_s with
      | MenhirState49 ->
          _menhir_run_50 _menhir_stack _menhir_lexbuf _menhir_lexer _v
      | MenhirState11 ->
          _menhir_run_45 _menhir_stack _menhir_lexbuf _menhir_lexer _v
      | _ ->
          _menhir_fail ()
  
  and _menhir_run_50 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v ->
      let MenhirCell1_expr (_menhir_stack, _menhir_s, x, _, _) = _menhir_stack in
      let xs = _v in
      let _v = _menhir_action_28 x xs in
      _menhir_goto_separated_nonempty_list_COMMA_expr_ _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
  
  and _menhir_run_45 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_LBRACKET -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v ->
      let x = _v in
      let _v = _menhir_action_22 x in
      _menhir_goto_loption_separated_nonempty_list_COMMA_expr__ _menhir_stack _menhir_lexbuf _menhir_lexer _v
  
  and _menhir_goto_loption_separated_nonempty_list_COMMA_expr__ : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_LBRACKET -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _v ->
      let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
      let _tok = _menhir_lexer _menhir_lexbuf in
      let MenhirCell1_LBRACKET (_menhir_stack, _menhir_s, _startpos__1_) = _menhir_stack in
      let (_endpos__3_, xs) = (_endpos, _v) in
      let _v = _menhir_action_02 xs in
      let (_endpos, _startpos) = (_endpos__3_, _startpos__1_) in
      let (_endpos_x_, _startpos_x_, x) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_03 _endpos_x_ _startpos_x_ x in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_run_43 : type  ttv_stack. ((((ttv_stack, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _endpos_0 = _menhir_lexbuf.Lexing.lex_curr_p in
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined2, _startpos_x_inlined2_, _endpos_x_inlined2_) = _menhir_stack in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined1, _startpos_x_inlined1_, _endpos_x_inlined1_) = _menhir_stack in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let MenhirCell1_JOIN (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos__8_, _endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) = (_endpos_0, _endpos, _startpos, _v) in
          let _v = _menhir_action_13 _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos__8_ _startpos_x_ _v _menhir_s _tok
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_41 : type  ttv_stack. (((ttv_stack, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState42 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_39 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_JOIN _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState40 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_37 : type  ttv_stack. ((((ttv_stack, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | RPAREN ->
          let _endpos_0 = _menhir_lexbuf.Lexing.lex_curr_p in
          let _tok = _menhir_lexer _menhir_lexbuf in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined2, _startpos_x_inlined2_, _endpos_x_inlined2_) = _menhir_stack in
          let MenhirCell1_expr (_menhir_stack, _, x_inlined1, _startpos_x_inlined1_, _endpos_x_inlined1_) = _menhir_stack in
          let MenhirCell0_LPAREN (_menhir_stack, _) = _menhir_stack in
          let MenhirCell1_DEMULT (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos__8_, _endpos_x_inlined3_, _startpos_x_inlined3_, x_inlined3) = (_endpos_0, _endpos, _startpos, _v) in
          let _v = _menhir_action_15 _endpos_x_ _endpos_x_inlined1_ _endpos_x_inlined2_ _endpos_x_inlined3_ _startpos_x_ _startpos_x_inlined1_ _startpos_x_inlined2_ _startpos_x_inlined3_ x x_inlined1 x_inlined2 x_inlined3 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos__8_ _startpos_x_ _v _menhir_s _tok
      | PLUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | _ ->
          _eRR ()
  
  and _menhir_run_35 : type  ttv_stack. (((ttv_stack, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState36 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  and _menhir_run_33 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_expr _menhir_cell0_MINUS as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA | ID _ | MINUS | OUT | PLUS | RBRACKET | RPAREN ->
          let MenhirCell0_MINUS (_menhir_stack, _) = _menhir_stack in
          let MenhirCell1_expr (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
          let _v = _menhir_action_07 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_inlined1_ _startpos_x_ _v _menhir_s _tok
      | _ ->
          _eRR ()
  
  and _menhir_run_31 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _ -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok ->
      let MenhirCell1_expr (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
      let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_09 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_inlined1_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_run_29 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_expr as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA | ID _ | MINUS | OUT | PLUS | RBRACKET | RPAREN ->
          let MenhirCell1_expr (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
          let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
          let _v = _menhir_action_06 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
          _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_inlined1_ _startpos_x_ _v _menhir_s _tok
      | _ ->
          _eRR ()
  
  and _menhir_run_26 : type  ttv_stack. (ttv_stack, _menhir_box_prog) _menhir_cell1_expr -> _ -> _ -> _ -> _ -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _tok ->
      let MenhirCell1_expr (_menhir_stack, _menhir_s, x, _startpos_x_, _endpos_x_) = _menhir_stack in
      let (_endpos_x_inlined1_, _startpos_x_inlined1_, x_inlined1) = (_endpos, _startpos, _v) in
      let _v = _menhir_action_08 _endpos_x_ _endpos_x_inlined1_ _startpos_x_ _startpos_x_inlined1_ x x_inlined1 in
      _menhir_goto_expr _menhir_stack _menhir_lexbuf _menhir_lexer _endpos_x_inlined1_ _startpos_x_ _v _menhir_s _tok
  
  and _menhir_run_24 : type  ttv_stack. ((ttv_stack, _menhir_box_prog) _menhir_cell1_DEMULT _menhir_cell0_LPAREN as 'stack) -> _ -> _ -> _ -> _ -> _ -> ('stack, _menhir_box_prog) _menhir_state -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer _endpos _startpos _v _menhir_s _tok ->
      let _menhir_stack = MenhirCell1_expr (_menhir_stack, _menhir_s, _v, _startpos, _endpos) in
      match (_tok : MenhirBasics.token) with
      | TIMES ->
          _menhir_run_25 _menhir_stack _menhir_lexbuf _menhir_lexer
      | PLUS ->
          _menhir_run_28 _menhir_stack _menhir_lexbuf _menhir_lexer
      | MINUS ->
          _menhir_run_32 _menhir_stack _menhir_lexbuf _menhir_lexer
      | DIV ->
          _menhir_run_30 _menhir_stack _menhir_lexbuf _menhir_lexer
      | COMMA ->
          let _menhir_s = MenhirState34 in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | NAME _v ->
              _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | MINUS ->
              _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LPAREN ->
              _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | LINEAR _v ->
              _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | LBRACKET ->
              _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
          | JOIN _v ->
              _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | INT _v ->
              _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | ID _v ->
              _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | FLOAT _v ->
              _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | DEMULT _v ->
              _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
  let _menhir_run_00 : type  ttv_stack. ttv_stack -> _ -> _ -> _menhir_box_prog =
    fun _menhir_stack _menhir_lexbuf _menhir_lexer ->
      let _tok = _menhir_lexer _menhir_lexbuf in
      match (_tok : MenhirBasics.token) with
      | ID _v ->
          let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
          let _endpos = _menhir_lexbuf.Lexing.lex_curr_p in
          let _menhir_stack = MenhirCell0_ID (_menhir_stack, _v, _startpos, _endpos) in
          let _tok = _menhir_lexer _menhir_lexbuf in
          (match (_tok : MenhirBasics.token) with
          | EQUALS ->
              let _tok = _menhir_lexer _menhir_lexbuf in
              (match (_tok : MenhirBasics.token) with
              | IN ->
                  let _tok = _menhir_lexer _menhir_lexbuf in
                  (match (_tok : MenhirBasics.token) with
                  | LPAREN ->
                      let _startpos = _menhir_lexbuf.Lexing.lex_start_p in
                      let _menhir_stack = MenhirCell0_LPAREN (_menhir_stack, _startpos) in
                      let _menhir_s = MenhirState04 in
                      let _tok = _menhir_lexer _menhir_lexbuf in
                      (match (_tok : MenhirBasics.token) with
                      | NAME _v ->
                          _menhir_run_05 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | MINUS ->
                          _menhir_run_07 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
                      | LPAREN ->
                          _menhir_run_08 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
                      | LINEAR _v ->
                          _menhir_run_09 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | LBRACKET ->
                          _menhir_run_11 _menhir_stack _menhir_lexbuf _menhir_lexer _menhir_s
                      | JOIN _v ->
                          _menhir_run_12 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | INT _v ->
                          _menhir_run_14 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | ID _v ->
                          _menhir_run_15 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | FLOAT _v ->
                          _menhir_run_21 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | DEMULT _v ->
                          _menhir_run_22 _menhir_stack _menhir_lexbuf _menhir_lexer _v _menhir_s
                      | _ ->
                          _eRR ())
                  | _ ->
                      _eRR ())
              | _ ->
                  _eRR ())
          | _ ->
              _eRR ())
      | _ ->
          _eRR ()
  
end

let prog =
  fun _menhir_lexer _menhir_lexbuf ->
    let _menhir_stack = () in
    let MenhirBox_prog v = _menhir_run_00 _menhir_stack _menhir_lexbuf _menhir_lexer in
    v
