open Position
open Serror
open Context
open Arithmetics

type exprType = [
  `RtBotFloat
  | `RtBotInt
  | `RtBotCollection
  | `RtBotVariable
  | `RtBotOperator
  | `RtBotLinear
  | `RtBotDemultiplexer
  | `RtBotJoin
  | `RtBotUnknow
]

type expr =
  [ `RtBotFloat of string located
  | `RtBotInt of string located
  | `RtBotPlus of arithmetic
  | `RtBotMinus of arithmetic
  | `RtBotTimes of arithmetic
  | `RtBotDiv of arithmetic
  | `RtBotParent of expr located
  | `RtBotUMinus of expr located
  | `RtBotVariable of variable
  | `RtBotOperator of operator
  | `RtBotLinear of operator
  | `RtBotDemultiplexer of operator
  | `RtBotJoin of operator 
  | `RtBotCollection of expr list located]

and variable = { variableName : string located; variableOutput : string located option }

and operator = {
  operatorName : string located;
  operatorSignature: string;
  operatorInput : expr located;
  operatorControl : expr located;
  operatorParams : expr located;
}

and arithmetic = { left: expr located; right: expr located}
and intOverflow = { intOverflowExpr: expr option; intResult: int}
and floatOverflow = { floatOverflowExpr: expr option; floatResult: float}

type assignment = { variableName : string located; assignmentValue : expr located }
type programInput = { inputName : string located; inputParams : expr located }
type programOutput = expr located
type program = { input : programInput; assignments : assignment list; output : programOutput }
type ast = 
[`Program of program 
  | `SyntaxFailure of RtBotError.t ]


let rec get_expr_type (e: expr): exprType = 
  match e with
  | `RtBotInt _ -> `RtBotInt
  | `RtBotFloat _ -> `RtBotFloat
  | `RtBotPlus a -> get_arithmetic_expr_type e a.left.value a.right.value
  | `RtBotMinus a -> get_arithmetic_expr_type e a.left.value a.right.value
  | `RtBotTimes a -> get_arithmetic_expr_type e a.left.value a.right.value
  | `RtBotDiv a -> get_arithmetic_expr_type e a.left.value a.right.value
  | `RtBotParent i -> get_expr_type i.value
  | `RtBotUMinus i -> get_expr_type i.value
  | `RtBotVariable _ -> `RtBotVariable
  | `RtBotOperator _ -> `RtBotOperator
  | `RtBotLinear _ -> `RtBotLinear
  | `RtBotDemultiplexer _ -> `RtBotDemultiplexer
  | `RtBotJoin _ -> `RtBotJoin
  | `RtBotCollection _ -> `RtBotCollection


and get_arithmetic_expr_type (e: expr) (left:expr) (right:expr): exprType =  
  let l = get_expr_type left in
  let r = get_expr_type right in
  match e, l, r with
  | `RtBotPlus _,`RtBotInt , `RtBotInt  -> `RtBotInt
  | `RtBotMinus _,`RtBotInt , `RtBotInt  -> `RtBotInt
  | `RtBotDiv _,`RtBotInt , `RtBotInt  -> `RtBotInt
  | `RtBotTimes _,`RtBotInt , `RtBotInt  -> `RtBotInt
  | _,`RtBotFloat , `RtBotFloat  -> `RtBotFloat
  | _,`RtBotFloat , `RtBotInt  -> `RtBotFloat
  | _,`RtBotInt , `RtBotFloat  -> `RtBotFloat
  | _ -> `RtBotUnknow
    


and is_expr_numeric(e:expr): bool =
  match get_expr_type e with
  | `RtBotFloat -> true
  | `RtBotInt -> true
  | _ -> false



let rec get_expr_int(e:expr): int =
  match e with
  | `RtBotInt il -> int_of_string il.value
  | `RtBotFloat fl -> int_of_float (float_of_string fl.value)
  | `RtBotPlus a -> (get_expr_int a.left.value) + (get_expr_int a.right.value)
  | `RtBotMinus a -> (get_expr_int a.left.value) - (get_expr_int a.right.value)
  | `RtBotTimes a -> (get_expr_int a.left.value) * (get_expr_int a.right.value)
  | `RtBotDiv a -> (get_expr_int a.left.value) / (get_expr_int a.right.value)
  | `RtBotUMinus i -> -1 * get_expr_int i.value
  | `RtBotParent i -> get_expr_int i.value
  | _ -> failwith(__FUNCTION__ ^ " expr is not an int")


let rec get_expr_float(e:expr): float =
  match e with
  | `RtBotInt il -> float_of_int (int_of_string il.value)
  | `RtBotFloat fl -> float_of_string fl.value
  | `RtBotPlus a -> (get_expr_float a.left.value) +. (get_expr_float a.right.value)
  | `RtBotMinus a -> (get_expr_float a.left.value) -. (get_expr_float a.right.value)
  | `RtBotTimes a -> (get_expr_float a.left.value) *. (get_expr_float a.right.value)
  | `RtBotDiv a -> (get_expr_float a.left.value) /. (get_expr_float a.right.value)
  | `RtBotUMinus i -> -1.0 *. get_expr_float i.value
  | `RtBotParent i -> get_expr_float i.value
  | _ -> failwith(__FUNCTION__ ^ " expr is not an int")


let int_of_expr(e:expr) :int =
  get_expr_int e


let float_of_expr(e:expr) :float =
  get_expr_float e


let collection_of_expr(e:expr): expr list =
match e with
| `RtBotCollection cl -> cl.value
| _ -> failwith(__FUNCTION__ ^ " expr is not a collection")


let operator_of_expr(e:expr): operator =
match e with
| `RtBotOperator o -> o
| `RtBotDemultiplexer o -> o
| `RtBotLinear o -> o
| `RtBotJoin o -> o
| _ -> failwith(__FUNCTION__ ^ " expr is not an operator")

let variable_of_expr(e:expr): variable =
match e with
| `RtBotVariable v -> v
| _ -> failwith(__FUNCTION__ ^ " expr is a variable")


let is_expr_int(e:expr): bool =
match get_expr_type e with
| `RtBotInt -> true
| _ -> false

let is_expr_float(e:expr): bool = 
match get_expr_type e with
| `RtBotFloat -> true
| _ -> false

    
let div_with_overflow (a:int) (b:int): int option =
  let a = Int64.of_int a and b = Int64.of_int b in
  (*ignoring division by zero, the divison_by_zero function will catch it*)
  let r = try Int64.div a b with Division_by_zero -> 0L in
  if r > Int64.of_int max_int || r < Int64.of_int min_int 
    then
      (*overflow*)
      None
    else
    Some (Int64.to_int r)

let int_of_string_overflow(s:string): int option =
      let r = try let _ = int_of_string s in None with Failure x -> Some x in
      if (Option.is_none r)
        then
          let y = Int64.of_int (int_of_string s) in
          if y >  Int64.of_int max_int || y < Int64.of_int min_int
            then None
          else Some (Int64.to_int y)
        else
          None  

let rec is_int_expr_overflow(e: expr): intOverflow =
match e with
| `RtBotInt il ->
  let value = int_of_string_overflow il.value in  
  if (Option.is_none value)
    then
      { intOverflowExpr = Some e; intResult = 0 }
    else
      { intOverflowExpr = None; intResult = Option.get value }
| `RtBotFloat fl -> 
  let value =
    try
      let _ = int_of_float (float_of_string fl.value) in None
    with Failure _ -> Some e in
    if (Option.is_some value)
      then
        { intOverflowExpr = value; intResult = 0}
      else
        { intOverflowExpr = None; intResult = int_of_float (float_of_string fl.value) }
| `RtBotPlus a -> 
  let left = is_int_expr_overflow a.left.value in
  let right = is_int_expr_overflow a.right.value in
  if (Option.is_some left.intOverflowExpr)
    then
      left
    else if (Option.is_some right.intOverflowExpr)
      then
        right     
    else
      let leftResult = left.intResult in
      let rightResult = right.intResult in
      let value = try (Some (Arithmetics.add leftResult rightResult) ) with Arithmetics.Overflow -> None in
      if (Option.is_none value)
        then
          { intOverflowExpr = Some e; intResult = 0}
        else
          { intOverflowExpr = None; intResult = Option.get value }
| `RtBotMinus a ->
  let left = is_int_expr_overflow a.left.value in
  let right = is_int_expr_overflow a.right.value in
  if (Option.is_some left.intOverflowExpr)
    then
      left
    else if (Option.is_some right.intOverflowExpr)
      then
        right     
    else
      let leftResult = left.intResult in
      let rightResult = right.intResult in
      let value = try (Some (Arithmetics.sub leftResult rightResult) ) with Arithmetics.Overflow -> None in
      if (Option.is_none value)
        then
          { intOverflowExpr = Some e; intResult = 0}
        else
          { intOverflowExpr = None; intResult = Option.get value}
| `RtBotTimes a ->
  let left = is_int_expr_overflow a.left.value in
  let right = is_int_expr_overflow a.right.value in
  if (Option.is_some left.intOverflowExpr)
    then
      left
    else if (Option.is_some right.intOverflowExpr)
      then
        right     
    else
      let leftResult = left.intResult in
      let rightResult = right.intResult in
      let value = try (Some (Arithmetics.mul leftResult rightResult) ) with Arithmetics.Overflow -> None in
      if (Option.is_none value)
        then
          { intOverflowExpr = Some e; intResult = 0}
        else
          { intOverflowExpr = None; intResult = Option.get value}
| `RtBotDiv a ->
  let left = is_int_expr_overflow a.left.value in
  let right = is_int_expr_overflow a.right.value in
  if (Option.is_some left.intOverflowExpr)
    then
      left
    else if (Option.is_some right.intOverflowExpr)
      then
        right     
    else
      let leftResult = left.intResult in
      let rightResult = right.intResult in
      let value = div_with_overflow leftResult rightResult in
      if (Option.is_none value)
        then
          { intOverflowExpr = Some e; intResult = 0}
        else
          (* The division by zero function will capture it, here we will ignore it *)
          { intOverflowExpr = None; intResult = Option.get value }
| `RtBotUMinus i -> 
  let inner = is_int_expr_overflow i.value in
  if (Option.is_some inner.intOverflowExpr)
    then
      inner        
    else
      let rightResult = inner.intResult in
      let leftResult = -1 in      
      let value = try (Some (Arithmetics.mul leftResult rightResult) ) with Arithmetics.Overflow -> None in
      if (Option.is_none value)
        then
          { intOverflowExpr = Some e; intResult = 0}
        else
          { intOverflowExpr = None; intResult = Option.get value}
| `RtBotParent i ->
  let inner = is_int_expr_overflow i.value in  
  if (Option.is_some inner.intOverflowExpr)
    then
      inner        
    else
      { intOverflowExpr = None; intResult = inner.intResult}
| _ -> { intOverflowExpr = None; intResult = 0}


let rec is_float_expr_overflow(e: expr): floatOverflow =
  match e with
  | `RtBotInt il ->
    let value =
    try
      let _ = float_of_int (int_of_string il.value) in None
    with Failure _ -> Some e in
    if (Option.is_some value)
      then
        { floatOverflowExpr = value; floatResult = 0.0 }
      else
        { floatOverflowExpr = None; floatResult = float_of_int (int_of_string il.value) }
  | `RtBotFloat fl -> 
    let value =
      try
        let _ = float_of_string fl.value in None
      with Failure _ -> Some e in
      if (Option.is_some value)
        then
          { floatOverflowExpr = value; floatResult = 0.0}
        else
          { floatOverflowExpr = None; floatResult = float_of_string fl.value }
  | `RtBotPlus a -> 
    let left = is_float_expr_overflow a.left.value in
    let right = is_float_expr_overflow a.right.value in
    if (Option.is_some left.floatOverflowExpr)
      then
        left
      else if (Option.is_some right.floatOverflowExpr)
        then
          right     
      else
        let leftResult = left.floatResult in
        let rightResult = right.floatResult in
        let value =
          try
            let _ = (leftResult +. rightResult) in None
          with Failure _ -> Some e in
        if (Option.is_some value)
          then
            { floatOverflowExpr = value; floatResult = 0.0}
          else
            { floatOverflowExpr = None; floatResult = leftResult +. rightResult}
  | `RtBotMinus a ->
    let left = is_float_expr_overflow a.left.value in
    let right = is_float_expr_overflow a.right.value in
    if (Option.is_some left.floatOverflowExpr)
      then
        left
      else if (Option.is_some right.floatOverflowExpr)
        then
          right     
      else
        let leftResult = left.floatResult in
        let rightResult = right.floatResult in
        let value =
          try
            let _ = (leftResult -. rightResult) in None
          with Failure _ -> Some e in
        if (Option.is_some value)
          then
            { floatOverflowExpr = value; floatResult = 0.0}
          else
            { floatOverflowExpr = None; floatResult = leftResult -. rightResult}
  | `RtBotTimes a ->
    let left = is_float_expr_overflow a.left.value in
    let right = is_float_expr_overflow a.right.value in
    if (Option.is_some left.floatOverflowExpr)
      then
        left
      else if (Option.is_some right.floatOverflowExpr)
        then
          right     
      else
        let leftResult = left.floatResult in
        let rightResult = right.floatResult in
        let value =
          try
            let _ = (leftResult *. rightResult) in None
          with Failure _ -> Some e in
        if (Option.is_some value)
          then
            { floatOverflowExpr = value; floatResult = 0.0}
          else
            { floatOverflowExpr = None; floatResult = leftResult *. rightResult}
  | `RtBotDiv a ->
    let left = is_float_expr_overflow a.left.value in
    let right = is_float_expr_overflow a.right.value in
    if (Option.is_some left.floatOverflowExpr)
      then
        left
      else if (Option.is_some right.floatOverflowExpr)
        then
          right     
      else
        let leftResult = left.floatResult in
        let rightResult = right.floatResult in
        let value =
          try
            let _ = (leftResult /. rightResult) in None
          with Failure _ -> Some e | Division_by_zero -> None in
        if (Option.is_some value)
          then
            { floatOverflowExpr = value; floatResult = 0.0}
          else
            (* The division by zero function will capture it, here we will ignore it *)
            { floatOverflowExpr = None; floatResult = try leftResult /. rightResult with Division_by_zero -> 0.0}
  | `RtBotUMinus i -> 
    let inner = is_float_expr_overflow i.value in  
    if (Option.is_some inner.floatOverflowExpr)
      then
        inner        
      else
        let innerResult = inner.floatResult in      
        let value =
          try
            let _ = -1.0 *. innerResult in None
          with Failure _ -> Some e in
        if (Option.is_some value)
          then
            { floatOverflowExpr = value; floatResult = 0.0}
          else
            { floatOverflowExpr = None; floatResult = -1.0 *. innerResult}
  | `RtBotParent i ->
    let inner = is_float_expr_overflow i.value in  
    if (Option.is_some inner.floatOverflowExpr)
      then
        inner        
      else
        { floatOverflowExpr = None; floatResult = inner.floatResult}
  | _ -> { floatOverflowExpr = None; floatResult = 0.0}


let is_expr_overflow (e: expr): expr option =
  if not(is_expr_numeric e)
    then 
      None
    else if (is_expr_int e)
      then
         let result = is_int_expr_overflow e in
          if (Option.is_some result.intOverflowExpr)
            then 
              result.intOverflowExpr
            else 
              None
    else if (is_expr_float e)
      then
        let result = is_float_expr_overflow e in
          if (Option.is_some result.floatOverflowExpr)
            then 
              result.floatOverflowExpr
            else 
              None
    else
      None


let is_expr_division_by_zero (e: expr): bool =
  if not(is_expr_numeric e)
    then 
      false
    else if (is_expr_int e)
      then
        try
          let _ = int_of_expr e in false
        with Division_by_zero -> true  
    else if (is_expr_float e)
      then
        try
          let _ = float_of_expr e in false
        with Division_by_zero -> true
    else    
      false

let is_expr_collection(e:expr): bool =
match e with
| `RtBotCollection _ -> true
| _ -> false

let is_expr_operator(e:expr): bool =
match e with
| `RtBotOperator _ -> true
| `RtBotDemultiplexer _ -> true
| `RtBotLinear _ -> true
| _ -> false

let is_expr_variable(e:expr): bool =
match e with
| `RtBotVariable _ -> true
| _ -> false

let position_of_expr(e:expr) : position =
match e with
| `RtBotInt il -> il.position
| `RtBotFloat fl -> fl.position
| `RtBotCollection cl -> cl.position
| `RtBotOperator o -> o.operatorName.position
| `RtBotVariable v -> v.variableName.position
| `RtBotDemultiplexer m -> m.operatorName.position
| `RtBotLinear l -> l.operatorName.position
| `RtBotJoin j -> j.operatorName.position
| `RtBotPlus a -> position_of_2_ordered_positions a.left.position a.right.position
| `RtBotMinus a -> position_of_2_ordered_positions a.left.position a.right.position
| `RtBotTimes a -> position_of_2_ordered_positions a.left.position a.right.position
| `RtBotDiv a -> position_of_2_ordered_positions a.left.position a.right.position
| `RtBotParent i -> i.position
| `RtBotUMinus i -> i.position

let option_int_of_symbol_type (st : RtBotContext.symbolType) : int option =
  match st with `Stream x -> Some x
    | `Operator -> None 
    | `None -> None


let string_of_int_located_option (i : string located option) : string =
  match i with 
  None -> "0" 
  | Some x -> x.value


let option_int_of_int_located_option (i : int located option) : int option =
  match i with None -> None | Some x -> Some (x.value)


let rec string_of_expr_list (l : expr list) =
  match l with
  | [] -> ""
  | h :: [] -> string_of_expr h
  | h :: t -> string_of_expr h ^ "," ^ string_of_expr_list t

and string_of_operator (o:operator) = 
  o.operatorName.value ^ "("
      ^ string_of_expr o.operatorInput.value
      ^ ","
      ^ string_of_expr o.operatorControl.value
      ^ ","
      ^ string_of_expr o.operatorParams.value
      ^ ")"


and string_of_expr (e : expr) =
  match e with
  | `RtBotFloat f ->  f.value 
  | `RtBotInt i ->   i.value 
  | `RtBotVariable v ->
       v.variableName.value ^ 
       if (Option.is_some v.variableOutput) then "["
      ^ string_of_int_located_option v.variableOutput
      ^ "]" else String.empty
  | `RtBotOperator o ->
      string_of_operator o
  | `RtBotDemultiplexer o ->
      string_of_operator o
  | `RtBotLinear o ->
    string_of_operator o
  | `RtBotJoin o ->
    string_of_operator o
  | `RtBotCollection c ->
     "[" ^ string_of_expr_list c.value ^ "]"
  | `RtBotPlus a ->
    string_of_expr a.left.value ^ " + " ^ string_of_expr a.right.value
  | `RtBotMinus a ->
      string_of_expr a.left.value ^ " - " ^ string_of_expr a.right.value
  | `RtBotTimes a ->
    string_of_expr a.left.value ^ " * " ^ string_of_expr a.right.value
  | `RtBotDiv a ->
    string_of_expr a.left.value ^ " / " ^ string_of_expr a.right.value
  | `RtBotParent i -> "( " ^ string_of_expr i.value  ^ " )"
  | `RtBotUMinus i -> "-" ^ string_of_expr i.value

let string_of_program_input (input : programInput) =
  "Input(" ^ input.inputName.value ^ "," ^ string_of_expr input.inputParams.value ^ ")\n"

let string_of_program_output (output : programOutput) = string_of_expr output.value

let string_of_assignment (a : assignment) =
  a.variableName.value ^ "," ^ string_of_expr a.assignmentValue.value

let rec string_of_assignments (al : assignment list) =
  match al with [] -> "" | h :: t -> string_of_assignment h ^ string_of_assignments t

let string_of_program (ast : program) =
  string_of_program_input ast.input ^ "\n"
  ^ string_of_assignments ast.assignments
  ^ "\n" ^ "Output("
  ^ string_of_expr ast.output.value
  ^ ")\n"
