open Ast
open Dictionary
open Config
open Position
open Context
open Semantic
open Utils


type signature = {
  context: RtBotContext.t;
  oName: string;
  sName: string;
  inputLength: int;
  controlLength: int;
  paramsLength: int;
}


let rec generate_config (o : operator) (fName:string) (inputLength: int option) (inputLengthMin: int option) (controlLength: int option) (controlLengthMin: int option) (paramsLength: int option) (paramsLengthMin: int option)(c : RtBotContext.t) : signature =
  
  let t1: RtBotContext.t * int =
  if (is_expr_collection o.operatorControl.value)
    then      
      let controlCollection = collection_of_expr o.operatorControl.value in
      let controlCollectionLength = List.length controlCollection in
      if (Option.is_some controlLength && controlCollectionLength <> (Option.get controlLength))
        then
          let context = get_error_006 o.operatorControl.position [(string_of_int (Option.get controlLength)) ; (string_of_int controlCollectionLength)] c in
          (context,0)
        else if (Option.is_some controlLengthMin && controlCollectionLength < (Option.get controlLengthMin)) then
          let context = get_error_019 o.operatorControl.position [ string_of_int controlCollectionLength ; o.operatorName.value ; string_of_int (Option.get controlLengthMin) ] c in
          (context,0)
        else
          (c,controlCollectionLength)
    else if (is_expr_operator o.operatorControl.value) then
      let controlOperator = operator_of_expr o.operatorControl.value in
      let ioc = (RtBotConfig.get_operator_definition controlOperator.operatorSignature c.operatorsConfig.operatorsMap) in
      if (Option.is_some ioc)
        then
          let controlCollectionLength = (List.length (Option.get ioc).control) in
          if (Option.is_some controlLength && controlCollectionLength <> (Option.get controlLength))
            then
              let context = get_error_006 o.operatorControl.position [(string_of_int (Option.get controlLength)) ; (string_of_int controlCollectionLength)] c in
              (context,0)
            else if (Option.is_some controlLengthMin && controlCollectionLength  < (Option.get controlLengthMin)) then
              let context = get_error_019 o.operatorControl.position [ string_of_int controlCollectionLength ; o.operatorName.value ; string_of_int (Option.get controlLengthMin) ] c in
              (context,0)
            else
              (c,controlCollectionLength)
        else
          let signature = generate_outlier_config o.operatorControl.value fName c in
          if (List.is_empty signature.context.errors)
            then
              let sName = signature.sName in
              let iocs = (RtBotConfig.get_operator_definition sName signature.context.operatorsConfig.operatorsMap) in
              if (Option.is_some iocs)
                then
                  let controlCollectionLength = (List.length (Option.get iocs).control) in
                  if (Option.is_some controlLength && controlCollectionLength <> (Option.get controlLength))
                    then
                      let context = get_error_006 o.operatorControl.position [(string_of_int (Option.get controlLength)) ; (string_of_int controlCollectionLength)] signature.context in
                      (context,0)
                    else if (Option.is_some controlLengthMin && controlCollectionLength  < (Option.get controlLengthMin)) then
                      let context = get_error_019 o.operatorControl.position [ string_of_int controlCollectionLength ; o.operatorName.value ; string_of_int (Option.get controlLengthMin) ] signature.context in
                      (context,0)
                    else
                      (signature.context,controlCollectionLength)
                else
                  let context = get_error_007 controlOperator.operatorName.position [ controlOperator.operatorName.value ] c in
                  (context,0)
            else
              (signature.context,0)
    else if (is_expr_variable o.operatorControl.value) then
      let controlVariable = variable_of_expr o.operatorControl.value in
      if (Option.is_none controlVariable.variableOutput)
        then
          let symbolOption = RtBotDictionary.get controlVariable.variableName.value c.symbols in
          if (Option.is_none symbolOption)
            then
              let context = get_error_012 controlVariable.variableName.position [controlVariable.variableName.value] c in
              (context,0)
            else if (Option.is_some symbolOption) then
              let symbol = Option.get symbolOption in
              if(symbol.sType = `Operator)
                then
                  let operator = Option.get (RtBotDictionary.get symbol.reference c.operators) in
                  let controlVariableLength = List.length operator.control in
                  if (Option.is_some controlLength && controlVariableLength <> (Option.get controlLength))
                    then
                      let context = get_error_006 o.operatorControl.position [(string_of_int (Option.get controlLength)) ; (string_of_int controlVariableLength)] c in
                      (context,0)
                    else if (Option.is_some controlLengthMin && controlVariableLength  < (Option.get controlLengthMin)) then
                      let context = get_error_019 o.operatorControl.position [ string_of_int controlVariableLength ; o.operatorName.value ; string_of_int (Option.get controlLengthMin) ] c in
                      (context,0)
                    else
                      (c,controlVariableLength)                  
                else
                  let context = get_error_022 controlVariable.variableName.position [controlVariable.variableName.value] c in
                  (context,0)
            else
              let context = get_error_012 controlVariable.variableName.position [controlVariable.variableName.value] c in
              (context,0)
        else
          let context = get_error_021 o.operatorControl.position [controlVariable.variableName.value; controlVariable.variableName.value] c in
          (context,0)
    else 
      let context = get_error_023 o.operatorControl.position [] c in
      (context,0)
  in
  let c1 = RtBotDictionary.get_key t1 in
  let controlLengthFound = RtBotDictionary.get_value t1 in
  let t2: RtBotContext.t * int =
  if (List.is_empty c1.errors)
    then
      if (is_expr_collection o.operatorInput.value)
        then
          let inputCollection = collection_of_expr o.operatorInput.value in
          let inputCollectionLength = List.length inputCollection in
          if ((Option.is_some inputLength) && (Option.get inputLength) <> inputCollectionLength)
            then
              let context = get_error_005 o.operatorInput.position [(string_of_int (Option.get inputLength)) ; (string_of_int (List.length inputCollection))] c1 in
              (context,0)
            else if (Option.is_some inputLengthMin && inputCollectionLength < (Option.get inputLengthMin)) then
              let context = get_error_019 o.operatorInput.position [ string_of_int inputCollectionLength ; o.operatorName.value ; string_of_int (Option.get inputLengthMin) ] c1 in
              (context,0)
            else
              (c1,inputCollectionLength)
        else if (is_expr_operator o.operatorInput.value) then
          let inputOperator = operator_of_expr o.operatorInput.value in
          let ioc = (RtBotConfig.get_operator_definition inputOperator.operatorSignature c1.operatorsConfig.operatorsMap) in
          if (Option.is_some ioc)
            then
              let inputOperatorLength = (List.length (Option.get ioc).input) in
              if ((Option.is_some inputLength) && (Option.get inputLength) <> inputOperatorLength)
                then
                  let context = get_error_005 o.operatorInput.position [(string_of_int (Option.get inputLength)) ; (string_of_int (List.length (Option.get ioc).input))] c1 in
                  (context, 0)
                else if (Option.is_some inputLengthMin && inputOperatorLength < (Option.get inputLengthMin)) then
                  let context = get_error_019 o.operatorInput.position [ string_of_int inputOperatorLength ; o.operatorName.value ; string_of_int (Option.get inputLengthMin) ] c1 in
                  (context,0)
                else
                  (c1, inputOperatorLength)
            else 
              let signature = generate_outlier_config o.operatorInput.value fName c in
              if (List.is_empty signature.context.errors)
                then
                  let sName = signature.sName in
                  let iocs = (RtBotConfig.get_operator_definition sName signature.context.operatorsConfig.operatorsMap) in
                  if (Option.is_some iocs)
                    then
                      let inputCollectionLength = (List.length (Option.get iocs).input) in
                      if (Option.is_some inputLength && inputCollectionLength <> (Option.get inputLength))
                        then
                          let context = get_error_005 o.operatorInput.position [(string_of_int (Option.get inputLength)) ; (string_of_int inputCollectionLength)] signature.context in
                          (context,0)
                        else if (Option.is_some inputLengthMin && inputCollectionLength  < (Option.get inputLengthMin)) then
                          let context = get_error_019 o.operatorInput.position [ string_of_int inputCollectionLength ; o.operatorName.value ; string_of_int (Option.get inputLengthMin) ] signature.context in
                          (context,0)
                        else
                          (signature.context,inputCollectionLength)
                    else
                      let context = get_error_007 inputOperator.operatorName.position [ inputOperator.operatorName.value ] c in
                      (context,0)
                else
                  (signature.context,0)
        else if (is_expr_variable o.operatorInput.value) then
          let inputVariable = variable_of_expr o.operatorInput.value in
          if (Option.is_none inputVariable.variableOutput)
            then
              let symbolOption = RtBotDictionary.get inputVariable.variableName.value c1.symbols in
              if (Option.is_none symbolOption)
                then
                  let context = get_error_012 inputVariable.variableName.position [inputVariable.variableName.value] c1 in
                  (context,0)                 
                else if (Option.is_some symbolOption) then
                  let symbol = Option.get symbolOption in
                  if(symbol.sType = `Operator)
                    then
                      let operator = Option.get (RtBotDictionary.get symbol.reference c1.operators) in
                      let inputVariableLength = List.length operator.input in
                      if ((Option.is_some inputLength) &&  (Option.get inputLength) <> inputVariableLength)
                        then
                          let context = get_error_005 o.operatorInput.position [(string_of_int (Option.get inputLength)) ; (string_of_int inputVariableLength)] c1 in
                          (context,0)
                        else if (Option.is_some inputLengthMin && inputVariableLength < (Option.get inputLengthMin) ) then
                          let context = get_error_019 o.operatorInput.position [ string_of_int inputVariableLength ; o.operatorName.value ; string_of_int (Option.get inputLengthMin) ] c1 in
                          (context,0)
                        else
                          (c1,inputVariableLength)
                    else
                      let context = get_error_022 inputVariable.variableName.position [inputVariable.variableName.value] c1 in
                      (context,0)
                else
                  let context = get_error_012 inputVariable.variableName.position [inputVariable.variableName.value] c1 in
                  (context,0)
            else
              let context = get_error_021 inputVariable.variableName.position [inputVariable.variableName.value; inputVariable.variableName.value] c1 in
              (context,0)
        else
          let context = get_error_023 o.operatorInput.position [] c1 in
          (context,0)
    else
      (c1, 0)
  in
  let c2 = RtBotDictionary.get_key t2 in
  let inputLengthFound = RtBotDictionary.get_value t2 in
  let t3: RtBotContext.t * int = 
  if (List.is_empty c2.errors)
    then      
      if (is_expr_collection o.operatorParams.value)
        then
          let paramsCollection = collection_of_expr o.operatorParams.value in
          let paramsCollectionLength = List.length paramsCollection in          
          if ((Option.is_some paramsLength) && (Option.get paramsLength) <> paramsCollectionLength)
            then
              let context = get_error_010 o.operatorParams.position [(string_of_int (Option.get paramsLength)) ; (string_of_int paramsCollectionLength)] c2 in
              (context,0)
            else if ((Option.is_some paramsLengthMin) && paramsCollectionLength < (Option.get paramsLengthMin)) then
              let context = get_error_019 o.operatorParams.position [ string_of_int paramsCollectionLength ; o.operatorName.value ; string_of_int (Option.get inputLengthMin) ] c2 in
              (context,0)
            else
              (c2, paramsCollectionLength)
        else
          let context = get_error_009 o.operatorParams.position [] c2 in
          (context, 0)
    else
      (c2,0)
  in  
  let c3 = RtBotDictionary.get_key t3 in
  let paramsLengthFound = RtBotDictionary.get_value t3 in
  let signature:string = o.operatorName.value ^ (string_of_int inputLengthFound) ^ (string_of_int controlLengthFound) ^ (string_of_int paramsLengthFound) in
  {
    context = c3;
    oName = o.operatorName.value;
    sName = signature;
    inputLength = inputLengthFound;
    controlLength = controlLengthFound;
    paramsLength = paramsLengthFound;
  }
    

and generate_outlier_config (e : expr) (fName: string) (c : RtBotContext.t) : signature =
  match e with
  | `RtBotLinear o ->
    let s = generate_config o fName None (Some 2) (Some 0) None (Some 1) None c in
    anotate_linear_signature o s fName
  | `RtBotDemultiplexer o ->    
    let s = generate_config o fName (Some 1) None None (Some 1) (Some 0) None c in
    anotate_multiplexer_signature s fName
  | `RtBotJoin o ->
    let s = generate_config o fName None (Some 2) (Some 0) None (Some 0) None c in
    anotate_join_signature s fName
  | _ -> 
    if (is_expr_operator e)
      then
        let op = operator_of_expr e in
        {
          context = get_error_007 op.operatorName.position [ op.operatorName.value ] c;
          oName = op.operatorName.value;
          sName = String.empty;
          inputLength = 0;
          controlLength = 0;
          paramsLength = 0;
        }
      else
        let pos = position_of_expr e in
        {
          context = get_error_023 pos [] c;
          oName = String.empty;
          sName = String.empty;
          inputLength = 0;
          controlLength = 0;
          paramsLength = 0;
        }

and anotate_multiplexer_signature (s: signature) (fName: string) : signature =
  if (List.is_empty s.context.errors)
    then      
      let config: RtBotConfig.operatorConfig = {
        name = fName;
        input = RtBotUtils.generate_ports "i" s.inputLength;
        control = RtBotUtils.generate_ports "c" s.controlLength;
        output = RtBotUtils.generate_ports "o" s.controlLength;
        params = [{ name = "numPorts"; minValue = (Some (float_of_int 2)); collection = false; dataType = [`PInt]; minItems = None }]
      } in
      let c4: RtBotContext.t =
      {
        s.context with
        operatorsConfig = {
          operatorsMap = if (not(RtBotDictionary.query s.sName s.context.operatorsConfig.operatorsMap)) then (s.sName, config) :: s.context.operatorsConfig.operatorsMap else s.context.operatorsConfig.operatorsMap
        }
      } 
      in
      {
        s with
        context = c4;
      }
    else
      s

and anotate_linear_signature (o: operator) (s: signature) (fName: string) : signature =
  if (List.is_empty s.context.errors)
    then      
      let paramsCollection = collection_of_expr o.operatorParams.value in
      let param = List.nth paramsCollection 0 in
      if (is_expr_collection param)
        then          
          let collection = collection_of_expr param in
          let length = List.length collection in          
          if (length = s.inputLength)
            then              
              let config: RtBotConfig.operatorConfig = {
                name = fName;
                input = RtBotUtils.generate_ports "i" s.inputLength;
                control = RtBotUtils.generate_ports "c" s.controlLength;
                output = RtBotUtils.generate_ports "o" 1;
                params = [{ name = "coeff"; minValue = None; collection = true; dataType = [`PFloat; `PInt]; minItems = (Some 2) }]
              } in
              let c4: RtBotContext.t =
              {
                s.context with
                operatorsConfig = {
                  operatorsMap = if (not(RtBotDictionary.query s.sName s.context.operatorsConfig.operatorsMap)) then (s.sName, config) :: s.context.operatorsConfig.operatorsMap else s.context.operatorsConfig.operatorsMap
                }
              } 
              in
              {
                s with
                context = c4;
              }
            else              
              {
                s with
                context = get_error_026 o.operatorName.position [string_of_int s.inputLength;  string_of_expr o.operatorInput.value; o.operatorName.value ;  string_of_expr param; string_of_int length ;string_of_int s.inputLength; string_of_int length ] s.context
              }
        else
          {
            s with
            context = get_error_015 (position_of_expr param) ["collection"; string_of_expr param] s.context
          }
    else
      s

and anotate_join_signature (s: signature) (fName: string) : signature =
  if (List.is_empty s.context.errors)
    then      
      let config: RtBotConfig.operatorConfig = {
        name = fName;
        input = RtBotUtils.generate_ports "i" s.inputLength;
        control = RtBotUtils.generate_ports "c" s.controlLength;
        output = RtBotUtils.generate_ports "o" s.inputLength;
        params = [{ name = "numPorts"; minValue = (Some (float_of_int 2)); collection = false; dataType = [`PInt]; minItems = None }]
      } in
      let c4: RtBotContext.t =
      {
        s.context with
        operatorsConfig = {
          operatorsMap = if (not(RtBotDictionary.query s.sName s.context.operatorsConfig.operatorsMap)) then (s.sName, config) :: s.context.operatorsConfig.operatorsMap else s.context.operatorsConfig.operatorsMap
        }
      } 
      in
      {
        s with
        context = c4;
      }
    else
      s