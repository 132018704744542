module RtBotDictionary = struct

  let empty = []

  let get_key (a, _) = a
  let get_value (_, b) = b

  let rec get_values (l : ('a * 'b) list) : 'b list =
    match l with [] -> [] | h :: t -> get_value h :: get_values t

  let rec query (key : 'a) (l : ('a * 'b) list) : bool =
    match l with [] -> false | h :: t -> if get_key h = key then true else query key t

  let rec get (key : 'a) (l : ('a * 'b) list) : 'b option =
    match l with [] -> None | h :: t -> if get_key h = key then Some (get_value h) else get key t

  let rec get_all (key : 'a) (l : ('a * 'b) list) : 'b list =
    match l with
    | [] -> []
    | h :: t -> if get_key h = key then get_value h :: get_all key t else get_all key t

  let add (pair : 'a * 'b) (l : ('a * 'b) list) : ('a * 'b) list =
    if query (pair |> get_key) l then failwith "Key already exists" else pair :: l

  let rec remove (key : 'a) (l : ('a * 'b) list) : ('a * 'b) list =
    if query key l |> not then l
    else match l with [] -> [] | h :: t -> if get_key h = key then t else h :: remove key t

  let rec random_id (l:('a * 'b) list): string =
    let r = Random.int 1000 |> string_of_int in
    if (not (query r l)) then r else random_id l

  let rec remove_all (key : 'a) (l : ('a * 'b) list) : ('a * 'b) list =
    if query key l |> not then l
    else
      match l with
      | [] -> []
      | h :: t -> if get_key h = key then remove_all key t else h :: remove_all key t
end
