module RtBotOperator = struct
  type pType = [ `RtBotInt of int | `RtBotFloat of float | `RtBotCollection of pType list ]
  type parameter = { pName : string; pType : pType }
  type t = { id : string; fName : string; oName : string; output: string list; input: string list; control: string list; parameters : parameter list }

  let string_of_parameter (param : parameter) = "Param(" ^ param.pName ^ ")"

  let rec string_of_parameter_list (params : parameter list) =
    "Parameters("
    ^
    match params with
    | [] -> ")"
    | h :: [] -> string_of_parameter h ^ ")"
    | h :: t -> string_of_parameter h ^ "," ^ string_of_parameter_list t

  let string_of_rtbot_operator (operator : t) =
    "Operator(id(" ^ operator.id ^ ")," ^ "fName(" ^ operator.fName ^ "),oName(" ^ operator.oName
    ^ "),"
    ^ string_of_parameter_list operator.parameters
    ^ ")"
end
