open Operator
open Connection
open Ast
open Dictionary
open Config
open Position
open Context
open Semantic
open Utils
open Outliers

let check_operator_param_type (e: expr) (t: RtBotConfig.paramsType): bool =
 match get_expr_type e,t with
 | `RtBotFloat , `PFloat -> true
 | `RtBotInt , `PInt -> true
 | _, _ -> false


let rec check_operator_param_types (e: expr) (types: RtBotConfig.paramsType list): expr option =
  match types with
  | [] -> (Some e)
  | h :: t -> if (check_operator_param_type e h) then None else check_operator_param_types e t
  

let rec check_operator_collection_param (e: expr list) (types: RtBotConfig.paramsType list): expr option =   
  match e with
  | [] -> None
  | h :: t ->
    let me =  check_operator_param_types h types in
    if (Option.is_some me)
    then      
      me 
    else      
      check_operator_collection_param t types


let match_operator_param_type (e: expr) (pc : RtBotConfig.param) (c: RtBotContext.t): RtBotContext.t option =
if (pc.collection && is_expr_collection e)
  then
    let el = collection_of_expr e in
    let me = check_operator_collection_param el pc.dataType in    
    if (Option.is_some me)
      then
        let expected: string =  RtBotConfig.string_of_param_type_list pc.dataType in        
        Some (get_error_014 (position_of_expr (Option.get me)) [ expected ; string_of_expr (Option.get me)] c)
      else 
        None
  else if (not (pc.collection) && not( is_expr_collection e))
    then
      let me = check_operator_param_types e pc.dataType in
      if (Option.is_some me)
        then
          let expected: string =  RtBotConfig.string_of_param_type_list pc.dataType in
          Some (get_error_015 (position_of_expr (Option.get me)) [ expected ; string_of_expr (Option.get me)] c)
        else 
          None
    else if (pc.collection && not( is_expr_collection e))
      then        
        Some (get_error_009 (position_of_expr e) [] c)
      else if (not(pc.collection) &&  (is_expr_collection e))
        then
          Some (get_error_004 (position_of_expr e) [] c)
        else
          None

let rec match_operator_param_types (ps : expr list) (pcs : RtBotConfig.param list) (c: RtBotContext.t) : RtBotContext.t option =
  match (ps, pcs) with
  | [], [] -> None
  | h1 :: t1, h2 :: t2 -> let contextOption = (match_operator_param_type h1 h2 c) in if (Option.is_some contextOption) then contextOption else match_operator_param_types t1 t2 c
  | _, _ -> failwith (__FUNCTION__ ^ " Params collection with different lengths")


let rec ptype_list_of_expr_list (ps : expr list): RtBotOperator.pType list =
  match ps with
  | [] -> []
  | h :: t -> ptype_of_expr h :: ptype_list_of_expr_list t


and ptype_of_expr (e: expr): RtBotOperator.pType =
  if (is_expr_numeric e)
    then
      match get_expr_type e with
      | `RtBotInt -> `RtBotInt (get_expr_int e)
      | `RtBotFloat -> `RtBotFloat (get_expr_float e)
      | _ -> failwith (__FUNCTION__ ^ " parameter is not numeric")
    else  
      match e with      
      | `RtBotCollection c -> `RtBotCollection (ptype_list_of_expr_list c.value)
      | _ -> failwith (__FUNCTION__ ^ " " ^ (string_of_expr e) ^ " not allowed as a parameter")


let generate_operator_param (e : expr) (pc : RtBotConfig.param) : RtBotOperator.parameter =
  if (is_expr_numeric e)
    then
      match get_expr_type e with
      | `RtBotInt -> { pName = pc.name; pType = `RtBotInt (get_expr_int e) }
      | `RtBotFloat -> { pName = pc.name; pType = `RtBotFloat (get_expr_float e) }
      | _ -> failwith (__FUNCTION__ ^ " parameter is not numeric")
    else  
      match e with   
      | `RtBotCollection c -> { pName = pc.name; pType = `RtBotCollection (ptype_list_of_expr_list c.value) }
      | _ -> failwith (__FUNCTION__ ^ " " ^ (string_of_expr e) ^ " not allowed as a parameter")
  

let rec generate_operator_params (ps : expr list) (pcs : RtBotConfig.param list) : RtBotOperator.parameter list =
  match (ps, pcs) with
  | [], [] -> []
  | h1 :: t1, h2 :: t2 -> generate_operator_param h1 h2 :: generate_operator_params t1 t2
  | _, _ -> failwith (__FUNCTION__ ^ " Params collection with different lengths")


let check_operator_param_constraints (on: string) (e : expr) (pc : RtBotConfig.param) (c : RtBotContext.t) : RtBotContext.t option =
  if (pc.collection && is_expr_collection e)
    then    
      let cl = collection_of_expr e in
      if (Option.is_some pc.minItems && (List.length cl) < (Option.get pc.minItems))
        then
          Some (get_error_019 (position_of_expr e) [ string_of_int(List.length cl) ; on ; string_of_int(Option.get pc.minItems) ] c )
        else
          None 
  else if (not (pc.collection) && not(is_expr_collection e) && is_expr_int e)
    then    
      let i = int_of_expr e in
      if (Option.is_some pc.minValue && i < int_of_float (Option.get pc.minValue))
        then
          Some (get_error_020 (position_of_expr e) [ string_of_expr e ; on ; string_of_int (int_of_float (Option.get pc.minValue)) ] c )
        else 
          None
  else if (not (pc.collection) && not(is_expr_collection e) && is_expr_float e)
    then    
      let f = float_of_expr e in
      if (Option.is_some pc.minValue && f < Option.get pc.minValue)
        then
          Some (get_error_020 (position_of_expr e) [ string_of_expr e ; on ; string_of_float ( (Option.get pc.minValue)) ] c )
        else 
          None        
  else
    None

let rec check_operator_params_constraints (ps : expr list) (pcs : RtBotConfig.param list) (on: string) (c : RtBotContext.t) : RtBotContext.t option =
  match (ps, pcs) with
  | [], [] -> None
  | h1 :: t1, h2 :: t2 -> let contextOption = check_operator_param_constraints on h1 h2 c in if Option.is_some contextOption then contextOption else check_operator_params_constraints t1 t2 on c
  | _, _ -> failwith (__FUNCTION__ ^ " Params collection with different lengths")


let rec check_operator_params_division_by_zero (ps : expr list) (c : RtBotContext.t) : RtBotContext.t option =
  match ps with
  | [] -> None
  | h1 :: t1 -> 
    let isDivByZero = is_expr_division_by_zero h1 in
    if isDivByZero 
      then 
        Some  (get_error_028 (position_of_expr h1) [string_of_expr h1] c)
      else 
        check_operator_params_division_by_zero t1 c


let rec check_operator_params_overflow (ps : expr list) (c : RtBotContext.t) : RtBotContext.t option =
  match ps with
  | [] -> None
  | h1 :: t1 -> 
    let oe = is_expr_overflow h1 in
    if (Option.is_some oe) 
      then 
        Some  (get_error_029 (position_of_expr (Option.get oe)) [string_of_expr (Option.get oe)] c)
      else 
        check_operator_params_overflow t1 c
  


let rec generate_connections (fromOp:RtBotOperator.t) (toOp:RtBotOperator.t) (input: string) (index: int) : RtBotConnection.t list = 
  if (index < (List.length fromOp.output))
    then
      let toPort =  
        if (input = "i") then List.nth toOp.input index else if (input = "c") then List.nth toOp.control index else failwith(__FUNCTION__ ^ " Unexpected input value " ^  input) in
      {
        fromOp = fromOp.id;
        toOp = toOp.id;
        toPort =  toPort;
        fromPort = List.nth fromOp.output index;
      } :: generate_connections fromOp toOp input (index + 1)
    else
      []

let rec generate_connections_to_index (fromOp:RtBotOperator.t) (toOp:RtBotOperator.t) (input: string) (from_index: int) (to_index: int) : RtBotConnection.t list = 
  if (from_index < (List.length fromOp.output))
    then
      let toPort =  
        if (input = "i") then List.nth toOp.input to_index else if (input = "c") then List.nth toOp.control to_index else failwith(__FUNCTION__ ^ " Unexpected input value " ^  input) in
      {
        fromOp = fromOp.id;
        toOp = toOp.id;
        toPort =  toPort;
        fromPort = List.nth fromOp.output from_index;
      } :: generate_connections_to_index fromOp toOp input (from_index + 1) to_index
    else
      []


let rec generate_section_expr_list (l : expr list) (id : string option) (index : int option) (input : string option) (c : RtBotContext.t) : RtBotContext.t =
  match l with
  | [] -> c
  | h :: t ->
      let c1 = (generate_section_expr h None id index input c).context in
      if List.is_empty c1.errors
        then
        let c2 = generate_section_expr_list t id (Some (Option.get index + 1)) input c1 in
        c2
      else c1  


and generate_section_expr (e : expr) (left: string located option) (id : string option) (index : int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  match e with
  | `RtBotOperator o -> generate_operator o id index input c
                          
  | `RtBotDemultiplexer _ -> generate_demultiplexer e id index input c

  | `RtBotLinear _ -> generate_linear e id index input c

  | `RtBotJoin _ -> generate_join e id index input c
                                
  | `RtBotVariable v -> generate_variable v left id index input c

  | `RtBotParent i ->  generate_section_expr i.value left id index input c
  
  | `RtBotCollection cl -> 
    {
      rightValue = { name = String.empty; category = `None };
      context = generate_section_inner_expr_list cl.value id index input c
    }
  | `RtBotFloat f  ->
    if (Option.is_none left)
      then 
        {
            rightValue = { name = String.empty; category = `None };
            context = get_error_011 f.position [f.value] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotInt i ->
    if (Option.is_none left) 
      then 
        {
            rightValue = { name = String.empty; category = `None };
            context = get_error_011 i.position [i.value] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotPlus _ ->
    if (Option.is_none left) 
      then
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_011 (position_of_expr e) [string_of_expr e] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotMinus _ ->
    if (Option.is_none left) 
      then
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_011 (position_of_expr e) [string_of_expr e] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotTimes _ ->
    if (Option.is_none left) 
      then
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_011 (position_of_expr e) [string_of_expr e] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotDiv _ ->
    if (Option.is_none left) 
      then
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_011 (position_of_expr e) [string_of_expr e] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }
  | `RtBotUMinus _ ->
    if (Option.is_none left)
      then
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_011 (position_of_expr e) [string_of_expr e] c
        }
      else
        {
          rightValue = { name = String.empty; category = `None };
          context = get_error_027 (Option.get left).position [(Option.get left).value] c
        }



and generate_section_inner_expr_list (l : expr list) (id : string option) (index : int option) (input : string option) (c : RtBotContext.t) : RtBotContext.t =
  match l with
  | [] -> c
  | h :: t ->
      let c1 = (generate_section_expr h None id index input c).context in
      if List.is_empty c1.errors
        then
        let c2 = generate_section_inner_expr_list t id (Some (Option.get index)) input c1 in
        c2
      else c1
  


and generate_operator (o : operator) (id : string option) (index: int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =  
  let ocOption = RtBotConfig.get_operator_definition o.operatorSignature c.operatorsConfig.operatorsMap in
  (* Get a unique id, one that is mot in the operators list *)
  let reference = RtBotDictionary.random_id c.operators in
  let rc: RtBotContext.t = 
  if (Option.is_some ocOption)
    then
      let oc = Option.get ocOption in

      let cx: RtBotContext.t =
      if (is_expr_collection o.operatorInput.value)
        then
          let inputCollection = collection_of_expr o.operatorInput.value in
          if ((List.length oc.input) <> (List.length inputCollection))
            then
              get_error_005 o.operatorInput.position [(string_of_int (List.length oc.input)) ; (string_of_int (List.length inputCollection))] c
            else
              c
        else if (is_expr_operator o.operatorInput.value) 
          then          
            let ioc = RtBotConfig.get_operator_definition o.operatorSignature c.operatorsConfig.operatorsMap in
            if (Option.is_some ioc)
              then
                if ( (List.length oc.input) <> (List.length (Option.get ioc).input))
                  then
                    get_error_005 o.operatorInput.position [(string_of_int (List.length oc.input)) ; (string_of_int (List.length (Option.get ioc).input))] c
                  else
                    c
              else
                c
        else if (not (is_expr_variable o.operatorInput.value))
          then 
            get_error_023 o.operatorInput.position []  c
          else
            c in
      
      let cy: RtBotContext.t =
      if (List.is_empty cx.errors)
        then
          if (is_expr_collection o.operatorControl.value) 
            then
              let controlCollection = collection_of_expr o.operatorControl.value in
              if ((List.length oc.control) <> (List.length controlCollection))
                then
                  get_error_006 o.operatorControl.position [(string_of_int (List.length oc.control)) ; (string_of_int (List.length controlCollection))] cx
                else
                  cx
            else if (is_expr_operator o.operatorControl.value) 
              then          
              let ioc = RtBotConfig.get_operator_definition o.operatorSignature cx.operatorsConfig.operatorsMap in
              if (Option.is_some ioc)
                then
                  if ( (List.length oc.control) <> (List.length (Option.get ioc).control))
                    then
                      get_error_006 o.operatorControl.position [(string_of_int (List.length oc.control)) ; (string_of_int (List.length (Option.get ioc).control))] cx
                    else
                      cx
                else
                  cx
            else if (not (is_expr_variable o.operatorInput.value))
              then 
                get_error_023 o.operatorControl.position []  cx
              else
                cx
        else 
          cx in

      if (List.is_empty cy.errors)
        then          
          if (is_expr_collection o.operatorParams.value)
            then              
              let paramsCollection = collection_of_expr o.operatorParams.value in
              if (List.length oc.params = (List.length paramsCollection))
                then
                  let overflowContextError = check_operator_params_overflow paramsCollection cy in
                  if (Option.is_none overflowContextError)
                    then
                      let divisionByZeroContextError = check_operator_params_division_by_zero paramsCollection cy in
                      if Option.is_none divisionByZeroContextError
                        then
                          let mismatchedContextError = match_operator_param_types paramsCollection oc.params cy in
                          if Option.is_none mismatchedContextError                     
                            then
                              let constraintContextError = check_operator_params_constraints paramsCollection oc.params o.operatorName.value cy in
                              if Option.is_none constraintContextError
                                then
                                  let oParameters = generate_operator_params paramsCollection oc.params in
                                  let fop : RtBotOperator.t =
                                    { id = reference; fName = oc.name; oName = o.operatorName.value; output = oc.output; input = oc.input; control = oc.control;  parameters = oParameters }
                                  in
                                  let c1 =
                                    {
                                      cy with
                                      operators = RtBotDictionary.add (reference, fop) cy.operators;
                                      connections =
                                        (if Option.is_some id && Option.is_some input && Option.is_none index
                                          then
                                            let top = Option.get (RtBotDictionary.get (Option.get id) cy.operators) in
                                            List.append (generate_connections fop top (Option.get input) 0) cy.connections
                                          else if Option.is_some id && Option.is_some input && Option.is_some index then
                                            let top = Option.get (RtBotDictionary.get (Option.get id) cy.operators) in
                                            List.append (generate_connections_to_index fop top (Option.get input) 0 (Option.get index)) cy.connections
                                          else cy.connections)
                                    }
                                  in
                                  let c2 = if (is_expr_collection o.operatorInput.value) 
                                    then
                                      let inputCollection = collection_of_expr o.operatorInput.value in
                                      generate_section_expr_list inputCollection (Some reference) (Some 0) (Some "i") c1
                                    else 
                                      (generate_section_expr o.operatorInput.value None (Some reference) None (Some "i") c1).context
                                  in
                                  let c3 = if (is_expr_collection o.operatorControl.value)
                                    then
                                      let controlCollection = collection_of_expr o.operatorControl.value in
                                      generate_section_expr_list controlCollection (Some reference) (Some 0) (Some "c") c2
                                    else 
                                      (generate_section_expr o.operatorControl.value None (Some reference) None (Some "c") c2).context in c3
                                else
                                  Option.get constraintContextError
                            else
                              Option.get mismatchedContextError
                        else 
                          Option.get divisionByZeroContextError
                    else
                      Option.get overflowContextError
                else 
                  get_error_010 o.operatorParams.position [(string_of_int (List.length oc.params)) ; (string_of_int (List.length paramsCollection))] c
            else              
              get_error_009 o.operatorParams.position [] c              
        else cy
    else get_error_007 o.operatorName.position [o.operatorName.value] c in
    { context = rc; 
      rightValue = { name = reference; category = `Operator }}


and generate_demultiplexer (e : expr) (id : string option) (index: int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  let operator = operator_of_expr e in
  let odO = RtBotConfig.get_outlier_definition operator.operatorName.value c.outliersConfig.outliersMap in
  if (Option.is_some odO)
    then
      let s = generate_outlier_config e (Option.get odO).name c in
      if (List.is_empty s.context.errors)
        then          
          let ocOption = RtBotConfig.get_operator_definition s.sName s.context.operatorsConfig.operatorsMap in
          if (Option.is_some ocOption)
            then  
              let controlLength = s.controlLength in
              let mOperator = {
                operatorName = operator.operatorName;
                operatorSignature = s.sName;
                operatorInput = operator.operatorInput;
                operatorControl = operator.operatorControl;
                operatorParams = { value =`RtBotCollection { value = [`RtBotInt { value = (string_of_int controlLength); position = operator.operatorParams.position}] ; position = operator.operatorParams.position } ; position = operator.operatorParams.position}   
              } in 
              generate_operator mOperator id index input s.context
            else
              {
                context =  get_error_007 operator.operatorName.position [operator.operatorName.value] s.context;
                rightValue =
                  {
                    name =  String.empty;
                    category = `None
                  }
              }
        else  
        {
          context =  s.context;
          rightValue =
            {
              name =  String.empty;
              category = `None
            }
        }
    else
      {
        context =  get_error_007 operator.operatorName.position [operator.operatorName.value] c;
        rightValue =
          {
            name =  String.empty;
            category = `None
          }
      }

and generate_generic_outlier (e : expr) (id : string option) (index: int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  let operator = operator_of_expr e in
  let odO = RtBotConfig.get_outlier_definition operator.operatorName.value c.outliersConfig.outliersMap in
  if (Option.is_some odO)
    then      
      let s = generate_outlier_config e (Option.get odO).name c in
      if (List.is_empty s.context.errors)
        then          
          let ocOption = RtBotConfig.get_operator_definition s.sName s.context.operatorsConfig.operatorsMap in
          if (Option.is_some ocOption)
            then
              let mOperator = {
                operatorName = operator.operatorName;
                operatorSignature = s.sName;
                operatorInput = operator.operatorInput;
                operatorControl = operator.operatorControl;
                operatorParams = operator.operatorParams;
              } in 
              generate_operator mOperator id index input s.context
            else
              {
                context =  get_error_007 operator.operatorName.position [operator.operatorName.value] s.context;
                rightValue =
                  {
                    name =  String.empty;
                    category = `None
                  }
              }
        else  
        {
          context =  s.context;
          rightValue =
            {
              name =  String.empty;
              category = `None
            }
        }
    else
      {
          context =  get_error_007 operator.operatorName.position [operator.operatorName.value] c;
          rightValue =
            {
              name =  String.empty;
              category = `None
            }
      }

and generate_linear (e : expr) (id : string option) (index: int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  generate_generic_outlier e id index input c

and generate_join (e : expr) (id : string option) (index: int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  let operator = operator_of_expr e in
  let odO = RtBotConfig.get_outlier_definition operator.operatorName.value c.outliersConfig.outliersMap in
  if (Option.is_some odO)
    then
      let s = generate_outlier_config e (Option.get odO).name c in
      if (List.is_empty s.context.errors)
        then          
          let ocOption = RtBotConfig.get_operator_definition s.sName s.context.operatorsConfig.operatorsMap in
          if (Option.is_some ocOption)
            then  
              let inputLength = s.inputLength in
              let mOperator = {
                operatorName = operator.operatorName;
                operatorSignature = s.sName;
                operatorInput = operator.operatorInput;
                operatorControl = operator.operatorControl;
                operatorParams = { value =`RtBotCollection { value = [`RtBotInt { value = (string_of_int inputLength); position = operator.operatorParams.position}] ; position = operator.operatorParams.position } ; position = operator.operatorParams.position}   
              } in 
              generate_operator mOperator id index input s.context
            else
              {
                context =  get_error_007 operator.operatorName.position [operator.operatorName.value] s.context;
                rightValue =
                  {
                    name =  String.empty;
                    category = `None
                  }
              }
        else  
        {
          context =  s.context;
          rightValue =
            {
              name =  String.empty;
              category = `None
            }
        }
    else
      {
        context =  get_error_007 operator.operatorName.position [operator.operatorName.value] c;
        rightValue =
          {
            name =  String.empty;
            category = `None
          }
      }

and generate_variable (v : variable) (left: string located option) (id : string option) (index : int option) (input : string option) (c : RtBotContext.t) : RtBotContext.contextPair =
  let so = RtBotDictionary.get v.variableName.value c.symbols in 
  if Option.is_none so || Option.is_none v.variableOutput || (Option.get so).sType = `Operator || (Option.get so).sType = `None
    then
      let fop = if Option.is_some so then (RtBotDictionary.get (Option.get so).reference c.operators) else None in      
      if Option.is_none so || Option.is_none fop || Option.is_none v.variableOutput || (Option.get so).sType != `Operator
      || Option.is_none id || Option.is_some index || Option.is_none input
        then
          if Option.is_none so || Option.is_none fop || Option.is_some v.variableOutput || (Option.get so).sType = `Operator || (Option.get so).sType = `None
          || Option.is_none id || Option.is_some index || Option.is_none input
            then
              if Option.is_none so || Option.is_none fop || Option.is_some v.variableOutput || (Option.get so).sType != `Operator
              || Option.is_none id || Option.is_some index || Option.is_none input || (Option.get input) <> "i" || (List.length (Option.get fop).output) = (List.length (Option.get (RtBotDictionary.get (Option.get id) c.operators)).input)
                then
                  if Option.is_none so || Option.is_none fop || Option.is_some v.variableOutput || (Option.get so).sType != `Operator
                  || Option.is_none id || Option.is_some index || Option.is_none input || (Option.get input) <> "c" || (List.length (Option.get fop).output) = (List.length (Option.get (RtBotDictionary.get (Option.get id) c.operators)).control)
                    then
                      let fromPort =
                        if Option.is_none v.variableOutput && Option.is_some so && (Option.get so).sType != `Operator && (Option.get so).sType != `None
                          then  Option.get (option_int_of_symbol_type (Option.get so).sType)
                        else if Option.is_some v.variableOutput && Option.is_some so && (Option.get so).sType = `Operator
                          then int_of_string (Option.get v.variableOutput).value
                        else if Option.is_none v.variableOutput && Option.is_some so && (Option.get so).sType = `Operator
                          then -1
                        else -1
                      in          
                      if (Option.is_none v.variableOutput || Option.is_none so || (Option.get so).sType != `Operator || Option.is_none fop || (Option.is_some fop && fromPort <= ((List.length (Option.get fop).output) - 1) && fromPort >= 0))
                        then
                          let connections : RtBotConnection.t list =
                            if
                              (Option.is_some id && Option.is_some index && Option.is_some input && Option.is_some so && Option.is_some fop && (fromPort <= ((List.length (Option.get fop).output) - 1) && fromPort >= 0))
                            then
                              let reference = (Option.get so).reference in                                  
                              let top = Option.get (RtBotDictionary.get (Option.get id) c.operators) in
                              let toPort =  
                                (if ((Option.get input) = "i") then List.nth top.input (Option.get index) else if ((Option.get input) = "c") then List.nth top.control (Option.get index) else failwith(__FUNCTION__ ^ " Unexpected input value " ^ (Option.get input))) in
                              {
                                fromOp = reference;
                                toOp = Option.get id;
                                toPort = toPort;
                                fromPort = List.nth (Option.get fop).output fromPort
                              }
                              :: c.connections
                            else if Option.is_some id && Option.is_none index && Option.is_some input && Option.is_some so && Option.is_some fop then                                  
                              let top = Option.get (RtBotDictionary.get (Option.get id) c.operators) in
                              List.append (generate_connections (Option.get fop) top (Option.get input) 0) c.connections
                            else if Option.is_some id && Option.is_some index && Option.is_some input && Option.is_some so && Option.is_some fop then                                  
                              let top = Option.get (RtBotDictionary.get (Option.get id) c.operators) in
                              List.append (generate_connections_to_index (Option.get fop) top (Option.get input) 0 (Option.get index)) c.connections
                            else c.connections
                          in
                          let pendingConnections: (string * RtBotContext.pendingConnection) list =
                            if
                              (Option.is_none so)
                            then     
                                ( v.variableName.value,
                                { name = v.variableName.value;
                                  left =  left;
                                  reference =  id;
                                  index =  index;
                                  input = input;
                                  pos = v.variableName.position;
                                  output = v.variableOutput }) :: c.pendingConnections
                            else c.pendingConnections
                          in
                          let c1 = { c with connections = connections; pendingConnections = pendingConnections} in              
                          {
                            context = c1;
                            rightValue =
                              if Option.is_some so && Option.is_some v.variableOutput &&  (Option.get so).sType = `Operator
                                then
                                    {
                                      name = v.variableName.value;
                                      category =`Stream (int_of_string (Option.get v.variableOutput).value)
                                    }
                                else if Option.is_some so && Option.is_none v.variableOutput && (Option.get so).sType = `Operator
                                  then
                                    let reference = (Option.get so).reference in
                                    {
                                      name = reference;
                                      category = `Operator
                                    }
                                else if Option.is_some so && Option.is_none v.variableOutput && (Option.get so).sType != `Operator && (Option.get so).sType != `None
                                  then 
                                    {
                                      name = v.variableName.value;
                                      category = (Option.get so).sType
                                    }
                                else 
                                  {
                                      name = String.empty;
                                      category = `None
                                  };
                          }
                        else
                          let reference = (Option.get so).reference in
                          let operator = Option.get (RtBotDictionary.get reference c.operators) in
                          let index = (List.length operator.output) - 1 in              
                          let expected = string_of_int index in
                          let received = string_of_int fromPort in
                          {
                            context = if (index > 0)
                                        then 
                                          get_error_016 v.variableName.position [v.variableName.value; operator.oName; expected; received] c
                                        else
                                          get_error_017 v.variableName.position [v.variableName.value; operator.oName; received] c;
                            rightValue =
                              {
                                name =  String.empty;
                                category = `None
                              }
                          }
                    else
                      let expected = (List.length (Option.get (RtBotDictionary.get (Option.get id) c.operators)).control) in
                      let received = (List.length (Option.get fop).output) in
                      {
                        context = get_error_006 v.variableName.position [string_of_int expected ; string_of_int received] c;
                        rightValue =
                          {
                            name =  String.empty;
                            category = `None
                          }
                      }
                else
                  let expected = (List.length (Option.get (RtBotDictionary.get (Option.get id) c.operators)).input) in
                  let received = (List.length (Option.get fop).output) in
                  {
                    context = get_error_005 v.variableName.position [string_of_int expected ; string_of_int received] c;
                    rightValue =
                      {
                        name =  String.empty;
                        category = `None
                      }
                  }
            else
              {
                context = get_error_022 v.variableName.position [v.variableName.value] c;
                rightValue =
                  {
                    name =  String.empty;
                    category = `None
                  }
              }
        else
          {
            context = get_error_021 v.variableName.position [v.variableName.value; v.variableName.value] c;
            rightValue =
              {
                name =  String.empty;
                category = `None
              }
          }        
    else
      let reference = (Option.get so).reference in
      let operator = Option.get (RtBotDictionary.get reference c.operators) in
      {
        context = get_error_013 v.variableName.position [v.variableName.value; operator.oName] c;
        rightValue =
          {
            name =  String.empty;
            category = `None
          }
      }


let rec connect_pending_connections (s : string) (pcs : RtBotContext.pendingConnection list) (c : RtBotContext.t) : RtBotContext.t =
  match pcs with
  | [] -> c
  | t :: h ->    
      let so = RtBotDictionary.get s c.symbols in
      if  Option.is_none so || Option.is_none t.output || (Option.get so).sType = `Operator || (Option.get so).sType = `None
        then
          let fop = if Option.is_some so then (RtBotDictionary.get (Option.get so).reference c.operators) else None in          
          if Option.is_none so || Option.is_none fop || Option.is_none t.output || (Option.get so).sType != `Operator
          || Option.is_none t.reference || Option.is_some t.index || Option.is_none t.input
            then
              if Option.is_none so || Option.is_none fop || Option.is_some t.output || (Option.get so).sType = `Operator || (Option.get so).sType = `None
              || Option.is_none t.reference || Option.is_some t.index || Option.is_none t.input
                then
                  if Option.is_none so || Option.is_none fop || Option.is_some t.output || (Option.get so).sType != `Operator
                  || Option.is_none t.reference || Option.is_some t.index || Option.is_none t.input || (Option.get t.input) <> "i" || (List.length (Option.get fop).output) = (List.length (Option.get (RtBotDictionary.get (Option.get t.reference) c.operators)).input)
                    then
                      if Option.is_none so || Option.is_none fop || Option.is_some t.output || (Option.get so).sType != `Operator
                      || Option.is_none t.reference || Option.is_some t.index || Option.is_none t.input || (Option.get t.input) <> "c" || (List.length (Option.get fop).output) = (List.length (Option.get (RtBotDictionary.get (Option.get t.reference) c.operators)).control)
                        then
                          let fromPort =
                            (* (Symbol exists) and (pending connection does not have an output) and (Symbol is not an operator but a stream) *)
                            if Option.is_some so && Option.is_none t.output && (Option.get so).sType != `Operator && (Option.get so).sType != `None 
                              then (Option.get (option_int_of_symbol_type (Option.get so).sType))
                            (* (Symbol exists) and (pending connection have an output) and (Symbol is an operator) *)
                            else if Option.is_some so && Option.is_some t.output && (Option.get so).sType = `Operator
                              then int_of_string (Option.get t.output).value
                            (* (Symbol exists) and (pending connection does not have an output) and (Symbol is an operator) *)
                            else if Option.is_some so && Option.is_none t.output && (Option.get so).sType = `Operator
                              then -1
                            (* otherwise *)
                            else -1
                          in
                          if (Option.is_none t.output || Option.is_none so || (Option.get so).sType != `Operator || Option.is_none fop || (Option.is_some fop && fromPort <= ((List.length (Option.get fop).output) - 1) && fromPort >= 0))
                            then
                              let connections : RtBotConnection.t list =
                                if (Option.is_some so && Option.is_some t.reference && Option.is_some t.input && Option.is_some t.index && Option.is_some fop && (fromPort <= ((List.length (Option.get fop).output) - 1) && fromPort >= 0))
                                  then                        
                                    let top = Option.get (RtBotDictionary.get (Option.get t.reference) c.operators) in
                                    let toPort =  
                                      (if ((Option.get t.input) = "i") then List.nth top.input (Option.get t.index) else if ((Option.get t.input) = "c") then List.nth top.control (Option.get t.index) else failwith(__FUNCTION__ ^ " Unexpected input value " ^ (Option.get t.input))) in
                                    {
                                      fromOp = (Option.get so).reference;
                                      toOp = Option.get t.reference;
                                      fromPort = List.nth (Option.get fop).output fromPort;
                                      toPort = toPort;
                                    }
                                    :: c.connections
                                  else if Option.is_some so && Option.is_some t.reference && Option.is_some t.input && Option.is_none t.index && Option.is_some fop then                                        
                                    let top = Option.get (RtBotDictionary.get (Option.get t.reference) c.operators) in
                                    List.append (generate_connections (Option.get fop) top (Option.get t.input) 0) c.connections
                                  else if Option.is_some t.reference && Option.is_some t.index && Option.is_some t.input && Option.is_some so && Option.is_some fop then                                  
                                    let top = Option.get (RtBotDictionary.get (Option.get t.reference) c.operators) in
                                    List.append (generate_connections_to_index (Option.get fop) top (Option.get t.input) 0 (Option.get t.index)) c.connections
                                  else c.connections in
                              (* solving for pending left$ = right$ *)
                              let symbols = 
                                if Option.is_some so && Option.is_none t.reference && Option.is_none t.input && Option.is_none t.index && Option.is_some t.left
                                  then            
                                    let symbol : RtBotContext.symbol = {
                                      reference = (Option.get so).reference; 
                                      sType = if Option.is_some t.output && (Option.get so).sType = `Operator 
                                                then 
                                                  `Stream (int_of_string (Option.get  t.output).value)
                                                else 
                                                  if Option.is_none t.output && (Option.get so).sType = `Operator
                                                    then 
                                                      `Operator
                                                else 
                                                  if Option.is_none t.output && (Option.get so).sType != `Operator && (Option.get so).sType != `None
                                                    then (Option.get so).sType
                                                else 
                                                  `None
                                    } in
                                    let csymbols = RtBotDictionary.add ((Option.get t.left).value, symbol) c.symbols in
                                    csymbols
                                  else
                                    c.symbols in          
                              let c1 = { c with connections = connections; symbols = symbols } in          
                              let c2 = connect_pending_connections s h c1 in c2
                            else
                              let index = (List.length (Option.get fop).output) - 1 in
                              let expected = string_of_int index in
                              let received = string_of_int fromPort in
                              if (index > 0)
                                then            
                                  get_error_016 t.pos [t.name; (Option.get fop).oName; expected; received] c
                                else
                                  get_error_017 t.pos [t.name; (Option.get fop).oName; received] c
                        else
                          let expected = (List.length (Option.get (RtBotDictionary.get (Option.get t.reference) c.operators)).control) in
                          let received = (List.length (Option.get fop).output) in
                          get_error_006 t.pos [string_of_int expected ; string_of_int received] c
                    else
                      let expected = (List.length (Option.get (RtBotDictionary.get (Option.get t.reference) c.operators)).input) in
                      let received = (List.length (Option.get fop).output) in
                      get_error_005 t.pos [string_of_int expected ; string_of_int received] c
                else
                  get_error_022 t.pos [t.name] c
            else
              get_error_021 t.pos [t.name; t.name] c            
        else
          let reference = (Option.get so).reference in
          let operator = Option.get (RtBotDictionary.get reference c.operators) in
          get_error_013 t.pos [t.name; operator.oName] c



let connect_pending_connections_by_key (s : string) (c : RtBotContext.t) : RtBotContext.t =  
  let pcs : RtBotContext.pendingConnection list = RtBotDictionary.get_all s c.pendingConnections in
  let c1 = connect_pending_connections s pcs c in
  { c1 with pendingConnections = RtBotDictionary.remove_all s c.pendingConnections }


let rec connect_pending_connections_in_context (pcs : (string * RtBotContext.pendingConnection) list) (c : RtBotContext.t) : RtBotContext.t =  
  match pcs with
  | [] -> c
  | h :: t ->          
      if Option.is_some (RtBotDictionary.get (RtBotDictionary.get_key h) c.symbols) then        
        let c1 = connect_pending_connections_by_key (RtBotDictionary.get_key h) c in
        let c2 = connect_pending_connections_in_context t c1 in
        c2
      else 
        let c1 = connect_pending_connections_in_context t c in
        c1

let rec process_pending_connections_in_context (c : RtBotContext.t) : RtBotContext.t =  
  let before = (List.length c.connections) + (List.length c.symbols) in
  let c1 =  if List.length c.pendingConnections > 0 
    then 
      connect_pending_connections_in_context c.pendingConnections c
    else 
      c 
  in
  let after = (List.length c1.connections) + (List.length c1.symbols) in
  if (before < after)
    then      
      process_pending_connections_in_context c1
    else
      c1


let generate_assignment (a : assignment) (c : RtBotContext.t) : RtBotContext.t =
  let cp = generate_section_expr a.assignmentValue.value (Some a.variableName) None None None c in
  let c1 = cp.context in
  let rv = cp.rightValue in
  if (List.is_empty c1.errors)
    then
      if (not (RtBotDictionary.query a.variableName.value c1.symbols))
        then
          let symbols =
            match rv.category with
            | `Operator ->            
                let symbol : RtBotContext.symbol = { reference = rv.name; sType = `Operator } in
                let csymbols = RtBotDictionary.add (a.variableName.value, symbol) c1.symbols in
                csymbols
            | `Stream s ->            
                if (RtBotDictionary.query rv.name c1.symbols)
                  then                
                    let eSymbol : RtBotContext.symbol = Option.get (RtBotDictionary.get rv.name c1.symbols) in
                    let symbol : RtBotContext.symbol = { reference = eSymbol.reference; sType =  `Stream s } in
                    let csymbols = RtBotDictionary.add (a.variableName.value, symbol) c1.symbols in
                    csymbols
                  else 
                    c1.symbols
            | `None -> c1.symbols
          in
          let c2 = { c1 with symbols = symbols; pendingConnections = c1.pendingConnections } in
          let c3 = process_pending_connections_in_context c2 in
          c3
        else
          get_error_001 a.variableName.position [a.variableName.value] c      
    else
      {
        c1 with
        operators = [];
        connections = [];
      }

let rec generate_assignment_list (statements : assignment list) (c : RtBotContext.t) : RtBotContext.t =
  match statements with
  | [] -> c
  | h :: t ->
      let c1 = generate_assignment h c in
      if List.is_empty c1.errors
        then   
          generate_assignment_list t c1
        else
          {
            c1 with
            operators = [];
            connections = [];
          }


let generate_in (i : programInput) (c : RtBotContext.t) : RtBotContext.t =
  let s : RtBotContext.symbol = { reference = RtBotDictionary.random_id c.operators; sType = `Operator } in
  if is_expr_int i.inputParams.value
    then
      let oe = is_expr_overflow i.inputParams.value in
      if (Option.is_none oe)
        then
          if (not(is_expr_division_by_zero i.inputParams.value))
            then
              let param: int = int_of_expr i.inputParams.value in
              let min: int = 1 and max: int = 10 in
              if (param >= min && param <= max)
                then 
                  let p : RtBotOperator.parameter =  { pName = "numPorts"; pType = `RtBotInt param } in                
                  let o : RtBotOperator.t = { id = s.reference; fName = "Input"; oName = "in"; output = (RtBotUtils.generate_ports "o" param); input = (RtBotUtils.generate_ports "i" param); control = [] ; parameters = [p] }
                  in
                  {
                    c with
                    entryOperator = s.reference;    
                    symbols = RtBotDictionary.add (i.inputName.value, s) c.symbols;
                    operators = RtBotDictionary.add (s.reference, o) c.operators;    
                  }
                else 
                  get_error_002 i.inputParams.position [(string_of_int param) ; (string_of_int min) ; (string_of_int max)] c
            else
              get_error_028 i.inputParams.position [string_of_expr i.inputParams.value] c
        else
          get_error_029 (position_of_expr (Option.get oe)) [string_of_expr (Option.get oe)] c
    else 
      get_error_008 i.inputParams.position [] c


let generate_out (output : programOutput) (c : RtBotContext.t) : RtBotContext.t =
  let reference = RtBotDictionary.random_id c.operators in
  if is_expr_collection output.value
    then
      let inputCollection = collection_of_expr output.value in
      if (not (List.is_empty inputCollection))
        then          
          let p : RtBotOperator.parameter = { pName = "numPorts"; pType = `RtBotInt (List.length inputCollection) }
          in
          let o : RtBotOperator.t = { id = reference; fName = "Output"; oName = "out"; output = (RtBotUtils.generate_ports "o" (List.length inputCollection)) ;input = (RtBotUtils.generate_ports "i" (List.length inputCollection)); control = [] ; parameters = [p] }
          in
          let cx = 
            { c 
              with 
              outputOperator = reference;
              operators = RtBotDictionary.add (reference, o) c.operators; 
            }
          in
          generate_section_expr_list inputCollection (Some reference) (Some 0) (Some "i") cx
        else get_error_003 output.position [] c
    else if is_expr_variable  output.value then
      let inputVariable = variable_of_expr output.value in
      let symbolOption = RtBotDictionary.get inputVariable.variableName.value c.symbols in
      if (Option.is_some symbolOption)
        then
          if ((Option.get symbolOption).sType = `Operator)
            then
              if (Option.is_none inputVariable.variableOutput)
                then
                  let operatorOption = RtBotDictionary.get (Option.get symbolOption).reference c.operators in
                  let outputPorts = (List.length (Option.get operatorOption).output) in
                  let p : RtBotOperator.parameter = { pName = "numPorts"; pType = `RtBotInt outputPorts }
                      in
                      let o : RtBotOperator.t = { id = reference; fName = "Output"; oName = "out"; output = (RtBotUtils.generate_ports "o" outputPorts) ;input = (RtBotUtils.generate_ports "i" outputPorts); control = [] ; parameters = [p] }
                      in
                      let cx = 
                        { c with
                          outputOperator = reference;
                          operators = RtBotDictionary.add (reference, o) c.operators; 
                        }
                      in
                      (generate_section_expr output.value None (Some reference) None (Some "i") cx).context
                else
                  get_error_021 inputVariable.variableName.position [inputVariable.variableName.value; inputVariable.variableName.value] c
            else          
              get_error_022 inputVariable.variableName.position [inputVariable.variableName.value] c
        else
          get_error_012 inputVariable.variableName.position [inputVariable.variableName.value] c
    else if is_expr_operator output.value then
      let inputOperator = operator_of_expr output.value in
      let ocOption = RtBotConfig.get_operator_definition inputOperator.operatorSignature c.operatorsConfig.operatorsMap in
      if Option.is_some ocOption
        then
          let outputPorts = (List.length (Option.get ocOption).output) in
          let p : RtBotOperator.parameter = { pName = "numPorts"; pType = `RtBotInt outputPorts }
          in
          let o : RtBotOperator.t = { id = reference; fName = "Output"; oName = "out"; output = (RtBotUtils.generate_ports "o" outputPorts) ;input = (RtBotUtils.generate_ports "i" outputPorts); control = [] ; parameters = [p] }
          in
          let cx = 
            { c               
              with 
              outputOperator = reference;
              operators = RtBotDictionary.add (reference, o) c.operators; 
            }
          in
        (generate_section_expr output.value None (Some reference) None (Some "i") cx).context
        else
          get_error_007 inputOperator.operatorName.position [inputOperator.operatorName.value] c
    else
      get_error_023 output.position [] c

let generate_program (program : program) (c : RtBotContext.t) : RtBotContext.t =
  let c1 = generate_in program.input c in  
  if (List.is_empty c1.errors)
    then      
      let c2 = generate_assignment_list program.assignments c1 in      
      if (List.is_empty c2.errors && List.is_empty c2.pendingConnections)
        then
          let c3 = generate_out program.output c2 in
          if (List.is_empty c3.errors && List.is_empty c3.pendingConnections)
            then c3
            else 
            {
              c3 with
              errors = List.append (generate_symbol_declaration_pending_errors_from_context c3) c3.errors;
              operators = [];
              connections = [];
            }
        else if (not (List.is_empty c2.errors))
          then
            {
              c2 with
              operators = [];
              connections = [];
            }
        else if (not (List.is_empty c2.pendingConnections))
          then
            {
              c2 with
              errors = List.append (generate_symbol_declaration_pending_errors_from_context c2) c2.errors;
              operators = [];
              connections = [];
            }
        else
          {
            c2 with
            operators = [];
            connections = [];
          }
    else
      {
        c1 with
        operators = [];
        connections = [];
      }
