{
open Parser
open Lexing

exception SyntaxError of string

let next_line lexbuf =
  let pos = lexbuf.lex_curr_p in
  lexbuf.lex_curr_p <-
    { pos with pos_bol = lexbuf.lex_curr_pos;
    pos_lnum = pos.pos_lnum + 1
    }
}


let white = [' ' '\t']+
let newline = '\r' | '\n' | "\r\n"
let digit = ['0'-'9']
let float =  digit+'.'digit+
let int =  digit+
let letter = ['a'-'z']
let id = (letter|digit)+'$'
let in = "in"
let out = "out"
let demult = "demult"
let linear = "linear"
let join = "join"
let name = letter+



rule read = 
  parse
  | white { read lexbuf }
  | newline { next_line lexbuf; read lexbuf }

  | "=" { EQUALS }
  | "(" { LPAREN }
  | ")" { RPAREN }
  | "[" { LBRACKET }
  | "]" { RBRACKET }
  | "," { COMMA }
  | "+" { PLUS }
  | "-" { MINUS }
  | "*" { TIMES }
  | "/" { DIV }
  | "#" { multi_line_comment lexbuf }
  | id { ID (Lexing.lexeme lexbuf) }
  | in { IN }
  | out { OUT }
  | demult { DEMULT (Lexing.lexeme lexbuf) }
  | linear { LINEAR (Lexing.lexeme lexbuf) }
  | join { JOIN (Lexing.lexeme lexbuf) }
  | name { NAME (Lexing.lexeme lexbuf) }
  | float { FLOAT ( (Lexing.lexeme lexbuf)) }
  | int { INT ( (Lexing.lexeme lexbuf)) }  
  | eof { EOF }
  | _ { raise (SyntaxError ("Unexpected char: " ^ Lexing.lexeme lexbuf)) }

  and multi_line_comment = parse
  | "#" { read lexbuf }
  | newline { next_line lexbuf; multi_line_comment lexbuf }
  | eof { raise (SyntaxError ("Lexer - Unexpected EOF - please terminate your comment.")) }
  | _ { multi_line_comment lexbuf }